import * as types from '@/actionTypes/globalTypes';

const namespace = types.NAMESPACE_GLOBAL;

export const fetchTrucks = () => ({
  type: `${namespace}/${types.FETCH_TRUCKS}`,
});

export const changeTruckModels = truck_type_id => ({
  type: `${namespace}/${types.CHANGE_TRUCK_MODELS}`,
  payload: {
    truck_type_id,
  },
});

export const fetchTaxNumberInfo = taxNumber => ({
  type: `${namespace}/${types.FETCH_TAX_NUMBER_INFO}`,
  payload: {
    taxNumber,
  },
});

// reducer
export const saveTrucks = ({ trucks }) => ({
  type: types.SAVE_TRUCKS,
  payload: {
    trucks,
  },
});

export const saveTruckModels = ({ truckModels }) => ({
  type: types.SAVE_TRUCK_MODELS,
  payload: {
    truckModels,
  },
});

export const saveCargoTypes = ({ cargoTypes }) => ({
  type: types.SAVE_CARGO_TYPES,
  payload: {
    cargoTypes,
  },
});

export const changeLayoutCollapsed = collapsed => ({
  type: `${namespace}/${types.CHANGE_LAYOUT_COLLAPSED}`,
  payload: {
    collapsed,
  },
});

export const saveTaxNumberInfo = ({ taxNumberInfo }) => ({
  type: `${namespace}/${types.SAVE_TAX_NUMBER_INFO}`,
  payload: {
    taxNumberInfo,
  },
});

export const saveQuickOrderData = ({ formData }) => ({
  type: `${namespace}/${types.SAVE_QUICKORDER_DATA}`,
  payload: {
    formData,
  },
});

export default {
  fetchTrucks,
  changeTruckModels,
  fetchTaxNumberInfo,
  saveTrucks,
  saveTruckModels,
  saveCargoTypes,
  saveTaxNumberInfo,
  changeLayoutCollapsed,
  saveQuickOrderData,
};
