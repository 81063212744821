// namespace
export const NAMESPACE_ORDER = 'order';
// order list
export const FETCH_ORDERS = 'fetchOrders';
export const SAVE_LIST_ORDERS = 'saveListOrders';
export const FILTER_ORDERS = 'filterOrder';
export const UPDATE_ORDER_IN_LIST = 'updateOrderInList';
// order detail
export const UPDATE_ORDER_STATUS = 'updateOrderStatus';
export const SAVE_ORDER_AFTER_UPDATE = 'saveOrderAfterUpdate';
export const SAVE_ORDER_DETAIL = 'saveOrderDetail';
export const GET_ORDER_BY_ID = 'getOrderById';
export const FETCH_ORDER_TEMPLATES = 'fetchOrderTemplates';
export const SET_ORDER_TEMPLATES = 'setOrderTemplates';
export const FETCH_ORDER_SUGGESTIONS = 'fetchOrderSuggestions';
export const SET_ORDER_SUGGESTIONS = 'setOrderSuggestions';
export const CREATE_ORDER = 'createOrder';
export const FETCH_INSTANT_PRICE = 'fetchInstantPrice';
// shipment
export const FETCH_SHIPMENT_DETAIL = 'fetchShipmentDetail';
export const FETCH_SHIPMENT_DOCUMENT = 'fetchShipmentDocument';
export const FETCH_MAP_OF_DRIVER = 'fetchMapOfDriver';
export const SAVE_SHIPMENT = 'saveShipment';
export const SAVE_LOCATIONS = 'saveLocations';
export const SAVE_SHIPMENT_BIDDINGS = 'saveShipmentBidding';
export const RESET_SHIPMENT_BIDDINGS = 'resetShipmentBidding';

/*
 * biddings
 */

export const FETCH_BIDDINGS = 'fetchBiddings';
export const SELECT_BIDDING = 'selectBidding';
export const DESELECT_BIDDING = 'deselectBidding';
export const ACCEPT_BIDDING = 'acceptBidding';
export const FETCH_SELECTED_BIDDINGS = 'fetchSelectedBiddings';
export const FETCH_BIDDINGS_SUCCESS = 'fetchBiddingsSuccess';
export const SELECT_BIDDING_SUCCESS = 'selectBiddingSuccess';
export const DESELECT_BIDDING_SUCCESS = 'deselectBiddingSuccess';
export const ACCEPT_BIDDING_SUCCESS = 'acceptBiddingSuccess';
export const FETCH_SELECTED_BIDDINGS_SUCCESS = 'fetchSelectedBiddingsSuccess';
