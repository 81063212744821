export default {
  'menu.home': 'Trang chủ',
  'menu.order': 'Danh sách đơn hàng',
  'menu.billing': 'Quản lý chi phí',
  'menu.create_new_order': 'Tạo đơn hàng mới',
  // Profile
  'menu.profile': 'Thông tin tài khoản',
  'menu.profile.individual': 'Thông tin cá nhân',
  'menu.profile.company': 'Thông tin doanh nghiệp',
  'menu.shipment': 'Theo dõi đơn hàng',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account.changeURL': 'Thay đổi đường dẫn',
  'menu.account.logout': 'Đăng xuất',
  'menu.account.other': 'Khác...',
  'menu.account.submit': 'Lưu',
  'menu.account.reset': 'Đặt lại',
  'menu.account.write_url_in_textbox': 'Hoặc nhập địa chỉ đường dẫn',
};
