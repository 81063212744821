export const SHIPMENT_STATUS = {
  CREATED: 'created',
  ARRIVED_PICKUP: 'arrived_pickup',
  PICKED_UP: 'picked_up',
  ARRIVED_DROPOFF: 'arrived_dropoff',
  DROPPED_OFF: 'dropped_off',
};

export const ORDER_STATUS = {
  POSTED: 'posted',
  EXPIRED: 'expired',
  HAS_BIDDING: 'has_bidding',
  BIDDING_QUOTED: 'bidding_quoted',
  BIDDING_ACCEPTED: 'bidding_accepted',
  SHIPMENT_NEW: 'shipment_new',
  SHIPMENT_IN_TRANSIT: 'shipment_in_transit',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

export const BROKER_STATUS = {
  VERIFIED: 'verified',
  UNVERIFIED: 'unverified',
};

export const COMPANY_STATUS = {
  VERIFIED: 'verified',
  UNVERIFIED: 'unverified',
};

export const TIME_CLOSE = 5;

export default {
  SHIPMENT_STATUS,
  ORDER_STATUS,
  BROKER_STATUS,
  TIME_CLOSE,
};
