export default {
  'order.order': 'Đơn hàng',
  'order.trip': 'Chuyến',
  'order.pickup-location': 'Điểm bốc hàng',
  'order.dropoff-location': 'Điểm dỡ hàng',
  'order.information': 'Thông tin đơn hàng',
  'order.order-detail': 'Chi tiết đơn hàng',
  'order.order-weight': 'Khối lượng hàng(tấn)',
  'order.created-date': 'Ngày tạo',
  'order.pickup_datetime': 'Ngày giờ bốc hàng',
  'order.pickup_date': 'Ngày bốc hàng',
  'order.pickup_time': 'Giờ bốc hàng',
  'order.dropoff-datetime': 'Ngày giờ dỡ hàng',
  'order.creator': 'Người tạo',
  'order.order-list': 'Danh sách đơn hàng',
  'order.order-type': 'Loại hàng',
  'order.truck_type_id': 'Loại xe',
  'order.truck_model_id': 'Trọng tải xe',
  'order.bidding_status': 'Trạng thái',
  'order.cargo_types': 'Hàng vận chuyển',
  'order.title.cargo_weight': 'Khối lượng hàng',
  'order.title.cargo_size': 'Kích thước hàng',
  'order.cargo_weight': '{weight} tấn',
  'order.text.truck_quantity': 'Số lượng xe',
  'order.truck_quantity': '{quantity} xe',
  'order.order-location': 'Địa chỉ đơn hàng',
  'order.order-information': 'Thông tin đơn',
  'order.truck-information': 'Thông tin xe vận chuyển',
  'order.service_type': 'Loại dịch vụ',
  'order.shipment_photo': 'Ảnh đính kèm',
  'order.shared_truck': 'Ghép xe',
  'order.price': 'Giá',
  'order.images': 'Ảnh',
  'order.newly_created': 'Mới tạo',
  'order.already_have_truck': 'Đã có xe',
  'order.canceled': 'Đã hủy',
  'order.search': 'Tìm kiếm',
  'order.pickup_address': 'Điểm bốc hàng',
  'order.dropoff_address': 'Điểm dỡ hàng',
  'order.example_adress': 'Ví dụ: Cát Lái',
  'order.order_id': 'Mã đơn hàng',
  'order.created_order_broker': 'Người tạo đơn',
  'order.example_broker': 'Ví dụ: Nguyen Van A',
  'order.address_is_incorrect': 'Địa chỉ không hợp lệ',
  'order.create_new_order': 'Tạo đơn hàng mới',
  'order.select_your_registered_trips_from_the_list': 'Chọn tuyến đường đã đăng kí từ danh sách',
  'order.add_a_new_trip': 'Thêm tuyến đường mới',
  'order.your_trip_name': 'Tuyến đường {{title}}',
  'order.order_with_new_trip': 'Đơn hàng tuyến đường mới',
  'order.shipment-detail': 'Thông tin đơn hàng',
  'order.preferences': 'Tùy chọn',
  'order.is_insurance_requested': 'Cần mua bảo hiểm',
  'order.hide_contact': 'Ẩn liên hệ',
  'order.is_tax_invoice_requested': 'Cần xuất hóa đơn VAT',
  'order.is_whole_truck': 'Cần nguyên xe',
  'order.is_share_truck': 'Cần ghép xe',
  'order.additional_info': 'Thông tin thêm',
  'order.business_photo': 'Ảnh chứng từ',
  'order.trip-detail': 'Thông tin tuyến đường',
  'order.pickup-location-do': 'Chi tiết DO điểm bốc',
  'order.dropoff-location-do': 'Chi tiết DO điểm dỡ',
  'order.pickup': 'hàng bốc',
  'order.dropoff': 'hàng dỡ',
  'order.delivery-order-code': 'Mã Delivery Order',
  'order.code-product-description': 'Mã / Mô tả sản phẩm',
  'order.total-unit': 'Tổng đơn vị',
  'order.total-weight': 'Tổng trọng lượng',
  'order.unit': 'Số đơn vị',
  'order.weight': 'Trọng lượng',
  'order.accepted': 'Đã chấp nhận',
  'order.pending': 'Chờ châp nhận',
  'order.show-bidding': 'Xem báo giá',
  'order.list-biddings': 'Danh sách báo giá đơn hàng',
  'order.order-bidding': 'Báo giá đơn hàng',
  'order.accept-bidding': 'Chấp nhận báo giá',
  'order.reject-bidding': 'Từ chối',
  'order.bidding-created-at': 'Ngày báo giá',
  'order.billing': 'Chi phí',
  'order.total-billing': 'Tổng kết chí phí',
  'order.total-billing-of-year': 'Tổng chi phí năm',
  'order.total-billing-of-month': 'Tổng chi phí tháng',
  'order.cost-type': 'Loại chi phí',
  'order.fee-name': 'Hạng mục chi',
  'order.quantity': 'Số lượng',
  'order.total-price': 'Thành tiền',
  'order.tax-rate': 'VAT',
  'order.total-price-with-tax': 'Tổng giá',
  'order.assigned-person': 'Người phụ trách',
  'order.posted': 'Đang tìm xe',
  'order.expired': 'Quá hạn',
  'order.cancelled': 'Đã huỷ',
  'order.completed': 'Hoàn thành',
  'order.has_bidding': 'Có báo giá',
  'order.bidding_quoted': 'Có báo giá',
  'order.bidding_accepted': 'Đã chốt giá',
  'order.shipment_new': 'Đã chốt xe',
  'order.shipment_in_transit': 'Đang chạy',
  'order.stt': 'STT',
  'order.route': 'Lộ trình',
  'order.shipment-documents': 'Thông tin hình ảnh hàng hoá và chứng từ',
  'shipment.trip': 'Hành trình',
  'shipment.share-trip': 'Chia sẻ hành trình',
  'shipment.documents': 'Chứng từ',
  'shipment.created': 'Nhận chuyến',
  'shipment.picked_up': 'Tài xế lấy hàng',
  'shipment.arrived_pickup': 'Tài xế đến điểm bốc',
  'shipment.in-transit': 'Đang giao hàng',
  'shipment.arrived_dropoff': 'Tài xế đến điểm dỡ',
  'shipment.dropped_off': 'Giao hàng hoàn tất',
  'shipment.documents-picked_up': 'Chứng từ bốc hàng',
  'shipment.documents-dropped_off': 'Chứng từ dỡ hàng',
  'shipment.follow-trip': 'Theo dõi hành trình',
  'order.enter-information': 'Điền thông tin',
  'order.logivan-bidding': 'Xem báo giá',
  'order.order-booking': 'Đặt xe',
  'order.whole_truck': 'Nguyên xe',
  'order.does_not_include_vat': 'Chưa bao gồm VAT',
  'order.enter_cargo_dimensions_to_get_shared_truck_price': 'Nhập kích thước hàng để có giá ghép',
  'order.LGV_have_not_supported_for_this_routes_and_cargo':
    'LOGIVAN chưa có cước vận chuyển cho tuyến đường và loại hàng này',
  'order.please_press': 'Vui lòng bấm',
  'order.to_view_order_and_LGV_will': 'để xem lại đơn hàng và LOGIVAN sẽ',
  'order.direct_bidding': 'báo giá trực tiếp',
  'order.for_you': 'cho bạn',
  'order.create_order_success': 'CHÚC MỪNG BẠN ĐÃ ĐẶT XE THÀNH CÔNG',
  'order.text_logivan_contact_for_you': 'LOGIVAN sẽ liên lạc ngay với bạn để chốt xe!',
  'order.text_back_home': 'Quay về trang danh sách đơn hàng trong',
  'order.sum': 'Tổng',
  'order.pretax_price': 'Giá trước thuế',
  'order.sub_total_price': 'Cước vận chuyển',
  'order.preview.pickup_location': 'Điểm đi',
  'order.preview.dropoff_location': 'Điểm đến',
  'order.preview.pickup_date': 'Ngày bốc hàng',
  'order.preview.truck_type': 'Loại xe',
  'order.preview.cargo_type': 'Loại hàng',
  'order.preview.notes': 'Ghi chú',
  'order.message.choice_shared_or_whole': 'Vui lòng chọn giá nguyên xe hoặc giá ghép hàng',
  'order.text.no_suitable_price': 'Chưa có giá phù hợp cho loại xe này',
  'order.text.no_shared_price_for_many_truck':
    'LGV chưa thể  hỗ  trợ ghép hàng với số lượng trên 1 xe',
  'order.text.with_this_payload_you_can_only_ride_the_vehicle':
    'Với trọng tải này bạn chỉ có thể đi nguyên xe',
  'order.title.bidding': 'Báo giá cho đơn hàng',
  'order.btn.ready_for_transport': 'Sẵn sàng vận chuyển',
  'order.btn.select_bidding': 'Chọn báo giá',
  'order.btn.unselect_bidding': 'Bỏ chọn',
  'order.btn.detail': 'Chi tiết',
  'order.btn.close': 'Đóng',
  'order.btn.cancel': 'Hủy bỏ',
  'order.btn.confirm': 'Đồng ý',
  'order.title.bidding_detail': 'Chi tiết báo giá',
  'order.title.shipment_info': 'Thông tin chủ xe',
  'order.title.transport_capacity': 'Năng lực vận tải',
  'order.title.sorry': 'Rất tiếc',
  'order.title.confirm_quote': 'Xác nhận báo giá!',
  'order.title.filter': 'Bộ lọc',
  'order.desc.suggested_price': 'Giá đề nghị',
  'order.desc.quote_price': 'Đã báo giá cho',
  'order.desc.date_bidding': 'Ngày báo giá',
  'order.desc.bidded_truck_for_order': 'Xe đã báo giá vận chuyển cho đơn hàng',
  'order.desc.error_not_enough_cars':
    'Số lượng xe vận chuyển vẫn chưa đủ để thực hiện việc chấp nhận đơn hàng. Bạn cần tìm đủ số  lượng xe để tiếp tục.',
  'order.desc.notification_enough_cars':
    'Số lượng xe vận chuyển từ báo giá đã đủ theo yêu cầu. Bạn có chắc chắn chấp nhận báo giá cho đơn hàng này.',
  'order.desc.not_bidding_for_order': 'Đơn hàng chưa có báo giá',
  'order.desc.truck-quantity': 'Số lượng',
  'order.desc.temporary-price': 'Giá tạm tính',
  'order.desc.warning-total-bid':
    'Bạn không thể chọn báo giá này vì số lượng xe vận chuyển sẽ vượt tổng số lượng xe yêu cầu.',
  'order.car': 'xe',
  'order.bidding_status.selected': 'Đã chọn',
  'order.bidding_status.pending': 'Đang chờ',
  'order.bidding_status.rejected': 'Từ chối',
  'order.bidding_status.cancelled': 'Đã hủy',
  'order.today': 'Hôm nay',
  'order.dayago': '{day} ngày trước',
};
