export default {
  'login.email': 'Địa chỉ email',
  'login.password': 'Mật khẩu',
  'login.message-invalid-credentials': 'Tài khoản không hợp lệ',
  'login.message-invalid-verification-code': 'Mã đăng nhập không hợp lệ',
  'login.tab-login-credentials': 'Đăng nhập email',
  'login.tab-login-mobile': 'Đăng nhập SĐT',
  'login.remember-me': 'Nhớ mật khẩu',
  'login.forgot-password': 'Bạn quên mật khẩu?',
  'login.forgot-password-description':
    'Xin vui lòng nhập email đã đăng ký và chúng tôi sẽ gửi thông tin khởi tạo lại mật khẩu mới cho bạn.',
  'login.enter-email': 'Nhập địa chỉ email',
  'login.sign-in-with': 'Sign in with',
  'login.signup': 'Sign up',
  'login.login': 'Đăng nhập',
  'validation.email.required': 'Vui lòng nhập email!',
  'validation.email.wrong-format': 'Email không hợp lệ!',
  'validation.password.required': 'Vui lòng nhập mật khẩu!',
  'validation.password.twice': 'Mật khẩu không đúng!',
  'validation.password.strength.msg':
    "Please enter at least 6 characters and don't use passwords that are easy to guess.",
  'validation.password.strength.strong': 'Strength: strong',
  'validation.password.strength.medium': 'Strength: medium',
  'validation.password.strength.short': 'Strength: too short',
  'validation.confirm-password.required': 'Please confirm your password!',
  'validation.phone-number.required': 'Vui lòng nhập số điện thoại!',
  'validation.phone-number.wrong-format': 'Số điện thoại không hợp lệ!',
  'validation.verification-code.required': 'Please enter the verification code!',
  'login.set-password': 'Thiết lập mật khẩu đăng nhập',
  'login.set-new-password': 'Thiết lập mật khẩu mới',
  'login.input-password': 'Nhập mật khẩu mới',
  'login.confirm-password': 'Xác nhận lại mật khẩu',
  'login.create-new-password': 'Tạo mật khẩu mới',
  'login.password_not_match': 'Mật khẩu không đúng',
};
