export default {
  'menu.home': 'Home',
  'menu.order': 'Order',
  'menu.billing': 'Billing',
  'menu.create_new_order': 'Create new order',
  // Profile
  'menu.profile': 'Account',
  'menu.profile.individual': 'Profile info',
  'menu.profile.company': 'Company info',
  'menu.shipment': 'Shipment',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account.changeURL': 'Change URL',
  'menu.account.logout': 'Logout',
  'menu.account.other': 'Other...',
  'menu.account.submit': 'Submit',
  'menu.account.reset': 'Reset',
  'menu.account.write_url_in_textbox': 'Or write in the below textbox',
};
