import {
  getListOrder,
  updateOrderStatus,
  fetchOrderTemplates,
  createOrder,
  getOrderById,
  getShipmentDetail,
  getMapOfDriver,
  fetchBiddings,
  selectBidding,
  deselectBidding,
  acceptBidding,
  fetchInstantPrice,
} from '@/services/order';
import moment from 'moment';
import { message } from 'antd';
import { formatMessage } from 'umi/locale';
import router from 'umi/router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Logger from '@/utils/logger';
import { formatPaging, doNothing } from '@/utils/utils';
import * as types from '@/actionTypes/orderTypes';
import { order_suggestions } from '@/constants/data';
import { DEFAULT_PAGE_NUMBER, LIMIT_PAGE } from '@/constants/tableConstants';
import * as actions from '@/actions/orderActions';
import profileActions from '@/actions/profileActions';
import loadingSelectors from '@/selectors/loadingSelectors';
import profileSelectors from '@/selectors/profileSelectors';
import orderSelectors from '@/selectors/orderSelectors';
import { ORDER_STATUS } from '@/constants/statusConstants';
import { ORDER_TYPE } from '@/constants/typeConstants';
import GoogleTagManagerServices from '@/services/google-tag-manager';

export default {
  namespace: types.NAMESPACE_ORDER,

  state: {
    orders: [],
    orderDetail: null,
    shipment: null,
    params: {},
    order_templates: [],
    order_suggestions: [],
    locations: [],
    /*
     * Biddings
     */
    biddings: [],
    listBiddings: [],
    listSelectedBiddings: [],
    pageListBiddings: [],

    shipmentBidding: {},
  },

  effects: {
    *[types.FETCH_ORDERS]({}, { call, put }) {
      const params = {
        page: 1,
        limit: 25,
      };
      const response = yield call(getListOrder, params);
      if (response) {
        const { pages, orders } = response;
        yield put(
          actions.saveListOrder({
            pages,
            orders: orders.sort((s1, s2) => s2.id - s1.id),
            params,
          })
        );
      } else {
        yield put(actions.saveListOrder({ params }));
      }
    },
    *[types.FILTER_ORDERS]({ payload }, { call, put }) {
      const { params = {} } = payload;
      if (!params.page) {
        params.page = 1;
      }
      params.limit = 25;
      const response = yield call(getListOrder, params);
      if (response) {
        const { pages, orders } = response;
        yield put(
          actions.saveListOrder({
            pages,
            orders: orders.sort((s1, s2) => s2.id - s1.id),
            params,
          })
        );
      } else {
        yield put(actions.saveListOrder({ params }));
      }
    },
    *[types.GET_ORDER_BY_ID]({ payload }, { call, put }) {
      yield put(actions.saveShipment({ shipment: null }));
      yield put(actions.saveLocations({ locations: [] }));
      try {
        const { orderID } = payload;
        const response = yield call(getOrderById, orderID);
        if (response) {
          const {
            order,
            order: { status, order_type: orderType },
          } = response;
          yield put(actions.saveOrderDetail({ order }));

          if (orderType !== ORDER_TYPE.ORIGINAL_HYBRID) {
            const params = {
              limit: LIMIT_PAGE,
              page: DEFAULT_PAGE_NUMBER,
            };

            if (status === ORDER_STATUS.BIDDING_ACCEPTED) {
              params.isFilterSelected = true;
            }

            yield put(actions.fetchBiddings(orderID, params));
          }
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.UPDATE_ORDER_STATUS]({ payload }, { call, put }) {
      const {
        orderID,
        params: { status = '' },
      } = payload;
      const response = yield call(updateOrderStatus, orderID, status);
      if (response) {
        yield put(actions.updateOrderInList({ order: response.order }));
      }
    },
    *[types.FETCH_ORDER_TEMPLATES]({}, { call, put, select, take }) {
      if (yield select(state => loadingSelectors.getIsFetchingCurrentUser(state))) {
        yield take(profileActions.saveCurrentUser({}).type);
      }
      const user = yield select(state => profileSelectors.getUser(state));
      Logger.log(user);
      const { broker_company_id = '' } = yield select(state => profileSelectors.getUser(state));
      if (broker_company_id) {
        const response = yield call(fetchOrderTemplates, broker_company_id);
        if (response) {
          yield put(actions.setOrderTemplates({ order_templates: response.order_templates }));
        }
      }
    },
    *[types.FETCH_ORDER_SUGGESTIONS]({}, { put, select, take }) {
      if (yield select(state => loadingSelectors.getIsFetchingCurrentUser(state))) {
        yield take(profileActions.saveCurrentUser({}).type);
      }
      yield put(actions.setOrderSuggestions({ order_suggestions }));
    },
    *[types.CREATE_ORDER]({ payload }, { call }) {
      try {
        const {
          order,
          params: { redirect, callback = doNothing },
        } = payload;
        const response = yield call(createOrder, order);
        if (response) {
          callback();
          if (redirect) router.push('/order');
          Logger.log(response);
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    // shipment
    *[types.FETCH_SHIPMENT_DETAIL]({ payload }, { call, put }) {
      try {
        const { shipmentId } = payload;
        if (shipmentId) {
          const response = yield call(getShipmentDetail, shipmentId);
          if (response) {
            yield put(actions.saveShipment({ shipment: response.shipment }));
          }
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.FETCH_MAP_OF_DRIVER]({}, { select, call, take, put }) {
      try {
        if (yield select(state => loadingSelectors.getIsFetchShipmentDetail(state))) {
          yield take(actions.saveShipment({}).type);
        }
        const shipment = yield select(state => orderSelectors.getShipment(state));
        if (shipment) {
          const driverId = get(shipment, 'driver.id', '');
          const pickupDatetime = get(shipment, 'order.pickup_datetime', new Date());
          const dropoffDatetime = get(shipment, 'order.dropoff_datetime', new Date());
          const startDate = moment(pickupDatetime).format('YYYYMMDD');
          const endDate = dropoffDatetime
            ? moment(dropoffDatetime).format('YYYYMMDD')
            : moment(new Date()).format('YYYYMMDD');
          const response = yield call(getMapOfDriver, driverId, startDate, endDate);
          if (response) {
            const { locations } = response;
            yield put(actions.saveLocations({ locations }));
          }
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    // biddings
    *[types.FETCH_BIDDINGS]({ payload }, { call, put }) {
      try {
        const { orderId, params } = payload;
        const response = yield call(fetchBiddings, orderId, params);
        if (isEmpty(response)) return;
        const { biddings: listBiddings, pages } = response;
        yield put(actions.fetchBiddingsSuccess({ listBiddings, pageListBiddings: pages }));
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.FETCH_SELECTED_BIDDINGS]({ payload }, { call, put }) {
      try {
        const { orderId } = payload;
        const response = yield call(fetchBiddings, orderId, { isFilterSelected: true });
        if (isEmpty(response)) return;
        const { biddings: listSelectedBiddings } = response;
        yield put(actions.fetchSelectedBiddingsSuccess(listSelectedBiddings));
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.SELECT_BIDDING]({ payload }, { call, put }) {
      try {
        const { orderId, biddingId, callback } = payload;
        const response = yield call(selectBidding, orderId, biddingId);
        if (isEmpty(response)) return;
        const { bidding } = response;
        yield put(actions.selectBiddingSuccess(bidding));
        callback();
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.DESELECT_BIDDING]({ payload }, { call, put }) {
      try {
        const { orderId, biddingId, callback } = payload;
        const response = yield call(deselectBidding, orderId, biddingId);
        if (isEmpty(response)) return;
        const { bidding } = response;
        yield put(actions.deselectBiddingSuccess(bidding));
        callback();
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.ACCEPT_BIDDING]({ payload }, { call, put }) {
      try {
        const { orderID } = payload;

        const response = yield call(acceptBidding, orderID);
        if (isEmpty(response)) return null;

        const { order } = response;
        yield put(actions.acceptBiddingSuccess(order));

        message.success(formatMessage({ id: 'form.order.accepted-bidding-success' }));
        return order;
      } catch (e) {
        return null;
      }
    },
    *[types.FETCH_INSTANT_PRICE]({ payload }, { call, put }) {
      try {
        const { values } = payload;
        if (isEmpty(values)) {
          yield put(actions.saveShipmentBiddings({ shipmentBidding: { error: true } }));
          return;
        }
        const response = yield call(fetchInstantPrice, values);
        if (response) {
          const {
            shared_truck_price: { unit_price: sharedUnitPrice },
            whole_truck_price: { unit_price: wholeUnitPrice },
          } = response;
          const hasSalePrice = !!(sharedUnitPrice || wholeUnitPrice);
          GoogleTagManagerServices.trackLogivanPrice(hasSalePrice);
          yield put(actions.saveShipmentBiddings({ shipmentBidding: response }));
        }
      } catch (error) {
        Logger.log(error);
      }
    },
  },
  reducers: {
    [types.SAVE_LIST_ORDERS](state, action) {
      return {
        ...state,
        orders: {
          list: action.payload.orders,
          pagination: formatPaging(action.payload.pages),
        },
        params: {
          ...action.payload.params,
          truck_type: Number(get(action, 'payload.params.truck_type', -1)),
          truck_model: Number(get(action, 'payload.params.truck_model', -1)),
        },
      };
    },
    [types.SAVE_ORDER_DETAIL](state, action) {
      const { order } = action.payload;
      const {
        biddings_truck_count: selectBidAmount,
        selected_biddings_amount: selectBidPrice,
      } = order;

      return {
        ...state,
        orderDetail: order,
        selectBidAmount,
        selectBidPrice,
      };
    },
    [types.UPDATE_ORDER_IN_LIST](state, action) {
      return {
        ...state,
        orders: {
          ...state.orders,
          list: state.orders.list.map(element => {
            if (element.id === action.payload.order.id)
              return { ...element, ...action.payload.order };
            return element;
          }),
        },
      };
    },
    [types.SET_ORDER_TEMPLATES](state, action) {
      return {
        ...state,
        order_templates: action.payload.order_templates,
      };
    },
    [types.SET_ORDER_SUGGESTIONS](state, action) {
      return {
        ...state,
        order_suggestions: action.payload.order_suggestions,
      };
    },
    [types.SAVE_SHIPMENT](state, action) {
      return {
        ...state,
        shipment: action.payload.shipment,
      };
    },
    [types.SAVE_LOCATIONS](state, action) {
      return {
        ...state,
        locations: action.payload.locations,
      };
    },
    [types.FETCH_BIDDINGS_SUCCESS](state, { payload }) {
      const { listBiddings, pageListBiddings } = payload;
      return {
        ...state,
        listBiddings,
        pageListBiddings,
      };
    },
    [types.FETCH_SELECTED_BIDDINGS_SUCCESS](state, { payload }) {
      return {
        ...state,
        listSelectedBiddings: payload.listSelectedBiddings,
      };
    },
    [types.SELECT_BIDDING_SUCCESS](state, { payload }) {
      const {
        bidding,
        bidding: {
          id,
          biddings_truck_count: selectBidAmount,
          selected_biddings_amount: selectBidPrice,
        },
      } = payload;
      const { listBiddings } = state;

      return {
        ...state,
        selectBidPrice,
        selectBidAmount,
        listBiddings: listBiddings.map(item => (item.id === id ? bidding : item)),
      };
    },
    [types.DESELECT_BIDDING_SUCCESS](state, { payload }) {
      const {
        bidding,
        bidding: {
          id,
          biddings_truck_count: selectBidAmount,
          selected_biddings_amount: selectBidPrice,
        },
      } = payload;
      const { listBiddings } = state;

      return {
        ...state,
        selectBidPrice,
        selectBidAmount,
        listBiddings: listBiddings.map(item => (item.id === id ? bidding : item)),
      };
    },
    [types.ACCEPT_BIDDING_SUCCESS](state, { payload }) {
      return {
        ...state,
        listBiddings: state.listSelectedBiddings,
        orderDetail: {
          ...state.orderDetail,
          ...payload.order,
        },
      };
    },
    [types.SAVE_SHIPMENT_BIDDINGS](state, action) {
      return {
        ...state,
        shipmentBidding: action.payload.shipmentBidding,
      };
    },
    [types.RESET_SHIPMENT_BIDDINGS](state) {
      return {
        ...state,
        shipmentBidding: {},
      };
    },
  },
};
