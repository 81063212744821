import { get, post, remove, put } from '@/utils/request';

export const getAccounts = companyId =>
  get({
    endpoint: `/broker_companies/${companyId}/brokers?page=1`,
  });

export const createAccount = (companyId, values) =>
  post({
    shouldRedirect: false,
    customUrl: true,
    endpoint: `/broker/broker_companies/${companyId}/add_broker`,
    params: {
      broker: values,
    },
  });

export const updateRoleAccount = value =>
  post({
    shouldRedirect: false,
    endpoint: `/user_roles`,
    params: value,
  });

export const deleteAccount = (companyId, brokerId) =>
  remove({
    shouldRedirect: false,
    customUrl: true,
    endpoint: `/broker/broker_companies/${companyId}/remove_broker/${brokerId}`,
  });

export const sendEmailAuthenticate = email =>
  post({
    endpoint: `/broker/brokers/password`,
    customUrl: true,
    params: {
      broker: { email },
    },
  });

export const resetPassword = params =>
  put({
    endpoint: `/broker/brokers/password`,
    customUrl: true,
    params: {
      broker: params,
    },
  });

export const setNewpassword = params =>
  put({
    endpoint: `/broker/brokers/invitation`,
    customUrl: true,
    params: {
      accept_invitation: params,
    },
  });
