import fetch from 'dva/fetch';
import { notification, message } from 'antd';
import router from 'umi/router';
import { formatMessage } from 'umi/locale';
import hash from 'hash.js';
import _ from 'lodash';
import config from './config';
import { getAccessToken } from '@/utils/authority';
import { download } from './utils';
import Logger from './logger';

const handleAllErrors = ({ errors, _error }) => {
  if (_error) message.error(_error);
  else if (errors) {
    const listKeyErrors = Object.keys(errors);
    listKeyErrors.forEach(key => {
      if (Array.isArray(errors[key])) {
        errors[key].forEach(error => message.error(error));
      } else message.error(errors[key]);
    });
  }
};

const checkStatus = async response => {
  const codeMessage = {
    200: formatMessage({ id: 'code.message.200' }),
    201: formatMessage({ id: 'code.message.201' }),
    204: formatMessage({ id: 'code.message.204' }),
    400: formatMessage({ id: 'code.message.400' }),
    401: formatMessage({ id: 'code.message.401' }),
    403: formatMessage({ id: 'code.message.403' }),
    404: formatMessage({ id: 'code.message.404' }),
    406: formatMessage({ id: 'code.message.406' }),
    410: formatMessage({ id: 'code.message.410' }),
    422: formatMessage({ id: 'code.message.422' }),
    500: formatMessage({ id: 'code.message.500' }),
    502: formatMessage({ id: 'code.message.502' }),
    503: formatMessage({ id: 'code.message.503' }),
    504: formatMessage({ id: 'code.message.504' }),
  };

  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  let errortext = '';
  if (response.status === 422) {
    const res = await response.json();
    handleAllErrors(res);
    return response;
  }
  errortext = codeMessage[response.status] || response.statusText;
  notification.error({
    // message: `${response.status}: ${response.url}`,
    message: `${response.status}`,
    description: errortext,
  });

  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
};

const cachedSave = (response, hashcode) => {
  /**
   * Clone a response data and store it in sessionStorage
   * Does not support data other than json, Cache only json
   */
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.match(/application\/json/i)) {
    // All data is saved as text
    response
      .clone()
      .text()
      .then(content => {
        sessionStorage.setItem(hashcode, content);
        sessionStorage.setItem(`${hashcode}:timestamp`, Date.now());
      });
  }
  return response;
};

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [option] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, option, customHeaders, shouldRedirect) {
  const options = {
    // expirys: isAntdPro(),
    ...option,
  };
  /**
   * Produce fingerprints based on url and parameters
   * Maybe url has the same parameters
   */
  const fingerprint = url + (options.body ? JSON.stringify(options.body) : '');
  const hashcode = hash
    .sha256()
    .update(fingerprint)
    .digest('hex');

  const newOptions = { ...options };
  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'PATCH' ||
    newOptions.method === 'DELETE'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.body = JSON.stringify(newOptions.body);
    }
  }

  newOptions.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken() || ''}`,
    ...newOptions.headers,
    ...customHeaders,
  };

  const expirys = options.expirys && 60;
  // options.expirys !== false, return the cache,
  if (options.expirys !== false) {
    const cached = sessionStorage.getItem(hashcode);
    const whenCached = sessionStorage.getItem(`${hashcode}:timestamp`);
    if (cached !== null && whenCached !== null) {
      const age = (Date.now() - whenCached) / 1000;
      if (age < expirys) {
        const response = new Response(new Blob([cached]));
        return response.json();
      }
      sessionStorage.removeItem(hashcode);
      sessionStorage.removeItem(`${hashcode}:timestamp`);
    }
  }
  return (
    fetch(url, newOptions)
      .then(checkStatus)
      .then(response => cachedSave(response, hashcode))
      .then(response => {
        // DELETE and 204 do not return data by default
        // using .json will report an error.
        if (newOptions.method === 'DELETE' || response.status === 204) {
          return response.text();
        }
        // we show error when check status , so we don't need return data
        if (response.status === 422) {
          return '';
        }
        if (Object.keys(customHeaders).length && !!customHeaders['Content-Type']) {
          response
            .text()
            .then(text => download(text, options.params))
            .catch(e => Logger.log(e));
        } else {
          return response.json();
        }
        return response.json();
      })
      /* eslint-disable */
      .catch(e => {
        const status = e.name;
        if (!shouldRedirect) {
          return {};
        }
        if (status === 401) {
          // @HACK
          /* eslint-disable no-underscore-dangle */
          window.g_app._store.dispatch({
            type: 'login/logout',
          });
        }
        // environment should not be used
        if (status === 403) {
          router.push('/exception/403');
        }
        if (status <= 504 && status >= 500) {
          router.push('/exception/500');
        }
        if (status >= 404 && status < 422) {
          router.push('/exception/404');
        }
      })
  );
}

export const get = async ({
  customUrl,
  endpoint,
  params = {},
  customHeaders = {},
  shouldRedirect = true,
}) => {
  const baseUrl = `${config.getServerPath()}/api/${config.version}`;
  let queryString = Object.keys(params)
    // filter null, -1, '', {} , []
    .map(key => {
      if (Array.isArray(params[key])) {
        if (!_.isEmpty(params[key])) return key;
        return null;
      }
      return key;
    })
    .filter(key => !_.isNil(params[key]))
    .filter(key => params[key] !== '')
    .filter(key => params[key] !== -1)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
  if (queryString.length > 0) {
    queryString = `?${queryString}`;
  }
  const url = `${customUrl ? config.getServerPath() : baseUrl}${endpoint}${queryString}`;
  const fetchParams = { method: 'GET', params };
  return request(url, fetchParams, customHeaders, shouldRedirect);
};

export const patch = async ({
  customUrl,
  endpoint,
  params = {},
  customHeaders = {},
  shouldRedirect = true,
}) => {
  const baseUrl = `${config.getServerPath()}/api/${config.version}`;
  const url = `${customUrl ? config.getServerPath() : baseUrl}${endpoint}`;
  const fetchParams = { method: 'PATCH', body: params };
  return request(url, fetchParams, customHeaders, shouldRedirect);
};

export const post = async ({
  customUrl,
  endpoint,
  params = {},
  customHeaders = {},
  shouldStringify = false,
  shouldRedirect = true,
}) => {
  const baseUrl = `${config.getServerPath()}/api/${config.version}`;
  const url = `${customUrl ? config.getServerPath() : baseUrl}${endpoint}`;
  const fetchParams = {
    method: 'POST',
    body: shouldStringify ? JSON.stringify(params) : params,
  };
  return request(url, fetchParams, customHeaders, shouldRedirect);
};

export const remove = async ({
  customUrl,
  endpoint,
  customHeaders = {},
  shouldRedirect = true,
}) => {
  const baseUrl = `${config.getServerPath()}/api/${config.version}`;
  const url = `${customUrl ? config.getServerPath() : baseUrl}${endpoint}`;
  const fetchParams = { method: 'DELETE' };
  return request(url, fetchParams, customHeaders, shouldRedirect);
};

export const put = async ({
  customUrl,
  endpoint,
  params = {},
  customHeaders = {},
  shouldRedirect = true,
}) => {
  const baseUrl = `${config.getServerPath()}/api/${config.version}`;
  const url = `${customUrl ? config.getServerPath() : baseUrl}${endpoint}`;
  const fetchParams = { method: 'PUT', body: params };
  return request(url, fetchParams, customHeaders, shouldRedirect);
};
