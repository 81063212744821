export default {
  'exception.back': 'Back to home',
  'exception.description.403': "Sorry, you don't have access to this page",
  'exception.description.404': 'Sorry, the page you visited does not exist',
  'exception.description.500': 'Sorry, the server is reporting an error',
  'code.message': 'The server is reporting an error',
  'code.message.200': 'The request was fulfilled.',
  'code.message.201': 'The request was fulfilled.',
  'code.message.204': 'The request was fulfilled.',
  'code.message.400': 'The server has not found anything',
  'code.message.401': 'Unauthorized.',
  'code.message.403': 'Forbidden.',
  'code.message.404': 'Not Found.',
  'code.message.406': 'Not Acceptable.',
  'code.message.410': 'Gone.',
  'code.message.422': 'Unprocessable Entity.',
  'code.message.500': 'Internal Server Error。',
  'code.message.502': 'Bad Gateway',
  'code.message.503': 'Service Unavailable',
  'code.message.504': 'Gateway Timeout',
};
