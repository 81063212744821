import profileModel from '@/models/profile';
import orderModel from '@/models/order';
import globalModel from '@/models/global';
import * as profileTypes from '@/actionTypes/profileTypes';
import * as orderTypes from '@/actionTypes/orderTypes';
import * as globalTypes from '@/actionTypes/globalTypes';

export const NAMESPACE_LOADING = 'loading';

// Profile
const getIsLoadingUpdateUser = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.UPDATE_USER}`];

const getIsFetchingCurrentUser = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.FETCH_CURRENT_USER}`];

const getIsUploadingIdentity = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.UPLOAD_IDENTITY}`];

const getIsFetchingCompany = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.FETCH_COMPANY}`];

const getIsLoadingCreateCompany = state =>
  state.loading.effects[`${profileModel.namespace}/${profileTypes.CREATE_BROKER_COMPANY}`];

const getIsLoadingUpdateCompany = state =>
  state.loading.effects[`${profileModel.namespace}/${profileTypes.UPDATE_BROKER_COMPANY}`];

const getIsFetchingAccounts = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.FETCH_ACCOUNTS}`];

const getIsDeleteAccount = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.DELETE_ACCOUNT}`];

const getIsUpdateRoleAccount = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.UPDATE_ROLE_ACCOUNT}`];

const getIsCreateAccount = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.CREATE_ACCOUNT}`];

const getIsUploadingPhotoCompany = state =>
  state[NAMESPACE_LOADING].effects[
    `${profileModel.namespace}/${profileTypes.UPLOAD_PHOTO_COMPANY}`
  ];

// Orders
const getIsFetchOrders = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_ORDERS}`] ||
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FILTER_ORDERS}`];

const getIsGetOrderById = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.GET_ORDER_BY_ID}`];

const getIsCreateOrder = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.CREATE_ORDER}`];

const getIsFetchShipmentDetail = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_SHIPMENT_DETAIL}`];

const getIsFetchMapOfDriver = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_MAP_OF_DRIVER}`];

const getIsFetchAllBiddings = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_BIDDINGS}`];

const getIsFetchSelectedBiddings = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_SELECTED_BIDDINGS}`];

const getIsSelectBidding = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.SELECT_BIDDING}`];

const getIsDeselectBidding = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.DESELECT_BIDDING}`];

const getIsAcceptBidding = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.ACCEPT_BIDDING}`];

const getIsFetchingInstantPrice = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_INSTANT_PRICE}`];
// Global
const getIsLoadingTaxNumber = state =>
  state[NAMESPACE_LOADING].effects[`${globalModel.namespace}/${globalTypes.FETCH_TAX_NUMBER_INFO}`];

export default {
  // Profile
  getIsFetchingCurrentUser,
  getIsLoadingUpdateUser,
  getIsUploadingIdentity,
  getIsFetchingCompany,
  getIsFetchingAccounts,
  getIsDeleteAccount,
  getIsCreateAccount,
  getIsUpdateRoleAccount,
  getIsUploadingPhotoCompany,
  getIsLoadingUpdateCompany,
  getIsLoadingCreateCompany,
  // Order
  getIsFetchOrders,
  getIsGetOrderById,
  getIsCreateOrder,
  getIsFetchShipmentDetail,
  getIsFetchMapOfDriver,
  getIsFetchAllBiddings,
  getIsAcceptBidding,
  getIsSelectBidding,
  getIsDeselectBidding,
  getIsFetchSelectedBiddings,
  getIsFetchingInstantPrice,
  // Global
  getIsLoadingTaxNumber,
};
