export default {
  'global.action': 'Thao tác',
  'global.overview': 'Tổng quan',
  'global.truck_type': 'Loại xe',
  'global.truck_model': 'Trọng tải xe',
  'global.all': 'Tất cả',
  'global.filter': 'Tra cứu',
  'global.clear': 'Xóa bộ lọc',
  'global.welcome_dashboard': 'Chào mừng đến bảng điều khiển',
  'global.dashboard_description': 'LOGIVAN giúp bạn tạo, lên kế hoạch và quản lý đơn hàng của mình',
  'global.create_new_order_now': 'Tạo đơn hàng ngay',
  'global.are-you-sure': 'Bạn có chắc không ?',
  'global.yes': 'Có',
  'global.no': 'Không',
  'global.info': 'Thông tin',
  'global.optional': 'Không bắt buộc',
  'global.view_images': 'Xem hình ảnh',
  'global.remove_images': 'Xóa hình ảnh',
  'global.click_here_to_upload_images': 'Bấm vào đây để tải ảnh',
  'global.no-name': 'Không có tên',
  'global.driver': 'Tài xế',
  'global.plate-number': 'Biển số xe',
  'global.no-info': 'Chưa có thông tin',
  'global.transport-capacity': 'Năng lực vận tải',
  'global.length': 'dài',
  'global.width': 'rộng',
  'global.height': 'cao',
  'global.tons': 'tấn',
  'global.download': 'Xuất dữ liệu',
  'global.cancel': 'Huỷ',
  'global.prev-month': 'Tháng trước',
  'global.next-month': 'Tháng tới',
  'global.from-date': 'Từ ngày',
  'global.to-date': 'Đến ngày',
  'global.hello': 'Xin chào',
  'global.export_data': 'Xuất dữ liệu',
  'global.account.owner': 'Chủ tài khoản',
  'global.account.logivan_name': 'Công Ty TNHH CÔNG NGHỆ VẬN TẢI LGV',
  'global.account.number': 'Số tài khoản',
  'global.account.bank': 'Ngân hàng',
  'global.account.bank_name':
    'TMCP Sài Gòn Thương Tín - CN Thủ Đô - Phòng giao dịch Hoàn kiếm (SACOMBANK)',
  'global.account.message_title': 'Nội dung chuyển tiền',
  'global.account.message_content': 'Tên & SĐT khách - ID Đơn hàng (trong phần danh sách đơn hàng)',
  'global.something_went_wrong': 'Có lỗi xảy ra',
  'global.come_back_later': 'Vui lòng quay lại sau!',
  'global.go_to_home': 'Trở về trang chủ',
  'global.read-and-agree-with-terms': 'Tôi đã đọc và đồng ý với các điều khoản từ Logivan.',
  'global.service-agreement': 'Hợp đồng thỏa thuận sử dụng dịch vụ<br />vận tải hàng hóa',
  'global.i-agree': 'Tôi đồng ý',
  'global.description-reset-password':
    'Chúng tôi đã gửi một email khởi tạo lại mật khẩu mới của bạn qua địa chỉ {email}. Xin vui lòng kiểm tra và xem trong hộp thư đến của bạn.',
  'global.comeback-login': 'Trở về trang đăng nhập',
};
