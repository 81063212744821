export const formatPhoneNumber = phone => {
  if (!phone) return '';
  if (phone && phone.charAt(0) === '0') {
    return `+84${phone.slice(1)}`;
  }
  if (phone && phone.charAt(0) === '+') {
    return phone;
  }
  return phone;
};

export const convertPhoneNumber = phone => {
  if (!phone) return '';
  if (phone && phone.charAt(0) === '+') {
    return phone.replace('+84', '0');
  }
  return phone;
};
