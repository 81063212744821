export default {
  'exception.back': 'Quay lại trang chủ',
  'exception.description.403': 'Xin lỗi, Bạn không có quyền truy cập trang này',
  'exception.description.404': 'Xin lỗi, Trang bạn truy xuất không tồn tại',
  'exception.description.500': 'Xin lỗi, Máy chủ tạm thời không hoạt động',
  'code.message': 'Có lỗi xảy ra vui lòng chờ trong ít phút',
  'code.message.200': 'Máy chủ tiếp nhận xử lý thành công。',
  'code.message.201': 'Yêu cầu được chấp nhận xử lý.',
  'code.message.204': 'Máy chủ tiếp nhận xử lý thành công。',
  'code.message.400': 'Server không thể xử lý',
  'code.message.401':
    'Người dùng không có quyền (mã thông báo, tên người dùng, mật khẩu không chính xác).',
  'code.message.403': 'Người dùng được ủy quyền, nhưng truy cập bị cấm。',
  'code.message.404': 'Nội dung không tìm thấy',
  'code.message.406': 'Các định dạng của yêu cầu không có sẵn.',
  'code.message.410': 'Tài nguyên được yêu cầu sẽ bị xóa vĩnh viễn và sẽ không được lấy.',
  'code.message.422': 'Xảy ra lỗi xác thực。',
  'code.message.500': 'Lỗi máy chủ, vui lòng kiểm tra máy chủ。',
  'code.message.502': 'Yêu cầu không hợp lệ',
  'code.message.503': 'Máy chủ quá tải hoặc đang bảo trì',
  'code.message.504': 'Máy chủ không hoạt động',
};
