import exception from './en-US/exception';
import form from './en-US/form';
import globalHeader from './en-US/globalHeader';
import login from './en-US/login';
import menu from './en-US/menu';
import settingDrawer from './en-US/settingDrawer';
import pwa from './en-US/pwa';
import order from './en-US/order';
import profile from './en-US/profile';
import measurement from './en-US/measurement';
import global from './en-US/global';
import quickOrder from './en-US/quickOrder';

export default {
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'home.introduce': 'introduce',
  'forms.basic.title': 'Basic form',
  'forms.basic.description':
    'Form pages are used to collect or verify information to users, and basic forms are common in scenarios where there are fewer data items.',
  ...exception,
  ...form,
  ...globalHeader,
  ...login,
  ...menu,
  ...settingDrawer,
  ...pwa,
  ...order,
  ...profile,
  ...measurement,
  ...global,
  ...quickOrder,
};
