import * as types from '@/actionTypes/profileTypes';

export const fetchUser = () => ({
  type: `${types.NAMESPACE_PROFILE}/${types.FETCH_CURRENT_USER}`,
});

export const updateUser = (brokerId, values, isRegister = false) => ({
  type: `${types.NAMESPACE_PROFILE}/${types.UPDATE_USER}`,
  payload: {
    brokerId,
    values,
    isRegister,
  },
});

export const fetchCompany = companyId => ({
  type: `${types.NAMESPACE_PROFILE}/${types.FETCH_COMPANY}`,
  payload: {
    companyId,
  },
});

export const createBrokerCompany = (values, isRegister = false) => ({
  type: `${types.NAMESPACE_PROFILE}/${types.CREATE_BROKER_COMPANY}`,
  payload: {
    values,
    isRegister,
  },
});

export const updateBrokerCompany = (companyId, values, isRegister = false) => ({
  type: `${types.NAMESPACE_PROFILE}/${types.UPDATE_BROKER_COMPANY}`,
  payload: {
    companyId,
    values,
    isRegister,
  },
});

export const uploadPhotoCompany = (companyId, values, isRegister = false) => ({
  type: `${types.NAMESPACE_PROFILE}/${types.UPLOAD_PHOTO_COMPANY}`,
  payload: {
    companyId,
    values,
    isRegister,
  },
});

export const fetchAccounts = () => ({
  type: `${types.NAMESPACE_PROFILE}/${types.FETCH_ACCOUNTS}`,
});

export const createAccount = (companyId, broker, callback) => ({
  type: `${types.NAMESPACE_PROFILE}/${types.CREATE_ACCOUNT}`,
  payload: {
    companyId,
    broker,
    callback,
  },
});

export const deleteAccount = (companyId, brokerId) => ({
  type: `${types.NAMESPACE_PROFILE}/${types.DELETE_ACCOUNT}`,
  payload: {
    companyId,
    brokerId,
  },
});

export const updateRoleAccount = values => ({
  type: `${types.NAMESPACE_PROFILE}/${types.UPDATE_ROLE_ACCOUNT}`,
  payload: { values },
});

export const uploadIdentity = (brokerId, params) => ({
  type: `${types.NAMESPACE_PROFILE}/${types.UPLOAD_IDENTITY}`,
  payload: { brokerId, params },
});
// reducer type

export const saveCurrentUser = ({ user = {} }) => ({
  type: types.SAVE_CURRENT_USER,
  payload: {
    user,
  },
});

export const uploadIdentitySuccess = document => ({
  type: types.UPLOAD_IDENTITY_SUCCESS,
  payload: {
    document,
  },
});

export const changeNotifyCount = ({ notifyCount, unreadCount }) => ({
  type: `${types.NAMESPACE_PROFILE}/${types.CHANGE_NOTIFY_COUNT}`,
  payload: {
    notifyCount,
    unreadCount,
  },
});

export const saveCompany = ({ company }) => ({
  type: [types.SAVE_COMPANY],
  payload: {
    company,
  },
});

export const saveAccounts = ({ brokers = [], pages = 0 }) => ({
  type: [types.SAVE_ACCOUNTS],
  payload: {
    brokers,
    pages,
  },
});

export const saveAccountAfterCreate = ({ account }) => ({
  type: [types.SAVE_ACCOUNT_AFTER_CREATE],
  payload: {
    account,
  },
});

export const saveAccountAfterDelete = ({ account_id }) => ({
  type: [types.SAVE_ACCOUNT_AFTER_DELETE],
  payload: {
    account_id,
  },
});

export const saveAccountAfterUpdateRole = ({ broker }) => ({
  type: [types.SAVE_ACCOUNT_AFTER_UPDATE_ROLE],
  payload: {
    broker,
  },
});

export const resetAccountAfterLogout = () => ({
  type: `${types.NAMESPACE_PROFILE}/${types.RESET_ACCOUNT_AFTER_LOGOUT}`,
});

export default {
  fetchUser,
  updateUser,
  fetchCompany,
  createBrokerCompany,
  updateBrokerCompany,
  uploadPhotoCompany,
  fetchAccounts,
  createAccount,
  deleteAccount,
  updateRoleAccount,
  saveAccounts,
  saveCompany,
  saveCurrentUser,
  saveAccountAfterCreate,
  saveAccountAfterDelete,
  saveAccountAfterUpdateRole,
  changeNotifyCount,
};
