import _ from 'lodash';
import { NAMESPACE_PROFILE } from '@/actionTypes/profileTypes';

const getCompanyId = state => _.get(state[NAMESPACE_PROFILE].user, 'broker_company.id', -1);
const getUser = state => state[NAMESPACE_PROFILE].user;
const getCompany = state => state[NAMESPACE_PROFILE].company;
const getListAccounts = state => state[NAMESPACE_PROFILE].listAccounts;
const getPagesListAccounts = state => state[NAMESPACE_PROFILE].pagesListAccounts;

export default {
  getUser,
  getCompany,
  getCompanyId,
  getListAccounts,
  getPagesListAccounts,
};
