import moment from 'moment';
import React from 'react';
import nzh from 'nzh/cn';
import get from 'lodash/get';
import { parse, stringify } from 'qs';
import { formatMessage } from 'umi/locale';

import { TABLE_PAGE_SIZE } from '@/constants/tableConstants';
import Logger from './logger';

export const doNothing = () => {};

export function upperCaseChar(string, idx = 0) {
  if (!string || typeof string !== 'string') return string;
  return (
    string.slice(0, idx) + string.charAt(idx).toUpperCase() + string.slice(idx + 1, string.length)
  );
}

export function checkValueZero(val) {
  /* eslint-disable-next-line */
  return !!val ? val : 0;
}

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getCurrentLang() {
  const lg = localStorage.getItem('umi_locale') || 'vi';
  const formatLg = lg.split('-');
  return Array.isArray(formatLg) ? formatLg[0] : 'vi';
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach(node => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

export function digitUppercase(n) {
  return nzh.toMoney(n);
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    Logger.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map(item => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function formatWan(val) {
  const v = val * 1;
  if (!v || Number.isNaN(v)) return '';

  let result = val;
  if (val > 10000) {
    result = Math.floor(val / 10000);
    result = (
      <span>
        {result}
        <span
          style={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            marginLeft: 2,
          }}
        >
          万
        </span>
      </span>
    );
  }
  return result;
}

// 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
export function isAntdPro() {
  return window.location.hostname === 'preview.pro.ant.design';
}

export const formatPaging = (pages = {}) => ({
  current: pages.current_page,
  total: pages.total_count,
});

/**
 * Pagination object
 */
export const configPagination = (pagination, pageSize = TABLE_PAGE_SIZE, position = 'both') => {
  const config = { ...pagination };
  config.total = get(pagination, 'total_count', 0);
  config.showQuickJumper = true;
  config.pageSize = pageSize;
  config.position = position;
  config.current = get(pagination, 'current_page', 0);
  return config;
};

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const download = (text, params) => {
  try {
    const { to_date, from_date } = params;
    const csv = text;
    let dataURI;
    let blob;
    if (csv === null) return null;

    const filename = `congno-${from_date}-${to_date}.csv`;
    const type = isSafari() ? 'application/csv' : 'text/csv';

    if (!csv.match(/^data:text\/csv/i)) {
      const BOM = '\uFEFF';
      blob = new Blob([BOM ? '\uFEFF' : '', csv], { type });
      dataURI = `data:${type};charset=utf-8,${BOM}${csv}`;
    }

    const URL = window.URL || window.webkitURL;
    const buildURI =
      typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', buildURI);
    link.setAttribute('download', filename);
    link.click();
    return null;
  } catch (e) {
    return Logger.log(e);
  }
};

export const downloadFileFromUrl = (dataurl, filename) => {
  const a = document.createElement('a');
  a.href = dataurl;
  a.setAttribute('download', filename);
  a.click();
};

export const generateRandomKey = () =>
  Math.random()
    .toString(36)
    .substring(7);

export const hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

export const getURLSearchParams = () => {
  const params = new URLSearchParams(window.location.search);
  return params;
};

export const formatAddress = address => address.replace(/, Việt Nam|, Viet Nam|, Vietnam/, '');

export const hideEmail = email => {
  const groupMatch = email.match(/^\w{4}(\w+)/);
  const strHide = get(groupMatch, '1', '');
  return email.replace(strHide, '*******');
};

export const checkCargoWeight = (_, value, callback) => {
  if (value < 0.3) callback(formatMessage({ id: 'form.menimum-cargo-weight' }));
  else callback();
};

export const getDistanceBetween = (p1 = { lat: 0, lng: 0 }, p2 = { lat: 0, lng: 0 }) => {
  const rad = x => (x * Math.PI) / 180;

  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};
