import * as types from '@/actionTypes/orderTypes';
import { LIMIT_PAGE, DEFAULT_PAGE_NUMBER } from '@/constants/tableConstants';

const namespace = types.NAMESPACE_ORDER;

export const fetchOrders = () => ({
  type: `${namespace}/${types.FETCH_ORDERS}`,
});

export const getOrderById = orderID => ({
  type: `${namespace}/${types.GET_ORDER_BY_ID}`,
  payload: {
    orderID,
  },
});

export const filterOrdersByParams = params => ({
  type: `${namespace}/${types.FILTER_ORDERS}`,
  payload: {
    params,
  },
});

export const updateOrderStatus = (orderID, params) => ({
  type: `${namespace}/${types.UPDATE_ORDER_STATUS}`,
  payload: {
    orderID,
    params,
  },
});

export const createOrder = (order, params) => ({
  type: `${namespace}/${types.CREATE_ORDER}`,
  payload: {
    order,
    params,
  },
});

export const fetchOrderTemplates = () => ({
  type: `${namespace}/${types.FETCH_ORDER_TEMPLATES}`,
});

export const fetchOrderSuggestions = () => ({
  type: `${namespace}/${types.FETCH_ORDER_SUGGESTIONS}`,
});

// shipment
export const fetchShipmentDetail = shipmentId => ({
  type: `${namespace}/${types.FETCH_SHIPMENT_DETAIL}`,
  payload: {
    shipmentId,
  },
});

export const fetchMapOfDriver = () => ({
  type: `${namespace}/${types.FETCH_MAP_OF_DRIVER}`,
});

// biddings
export const fetchBiddings = (
  orderId,
  { amount, isFilterSelected, limit = LIMIT_PAGE, page = DEFAULT_PAGE_NUMBER }
) => ({
  type: `${namespace}/${types.FETCH_BIDDINGS}`,
  payload: {
    orderId,
    params: {
      page,
      limit,
      amount,
      isFilterSelected,
    },
  },
});

export const fetchBiddingsSuccess = ({ listBiddings, pageListBiddings }) => ({
  type: types.FETCH_BIDDINGS_SUCCESS,
  payload: {
    listBiddings,
    pageListBiddings,
  },
});

export const fetchSelectedBiddings = orderId => ({
  type: `${namespace}/${types.FETCH_SELECTED_BIDDINGS}`,
  payload: {
    orderId,
  },
});

export const fetchSelectedBiddingsSuccess = listSelectedBiddings => ({
  type: types.FETCH_SELECTED_BIDDINGS_SUCCESS,
  payload: {
    listSelectedBiddings,
  },
});

export const selectBidding = ({ orderId, biddingId, callback }) => ({
  type: `${namespace}/${types.SELECT_BIDDING}`,
  payload: {
    orderId,
    biddingId,
    callback,
  },
});

export const selectBiddingSuccess = bidding => ({
  type: types.SELECT_BIDDING_SUCCESS,
  payload: {
    bidding,
  },
});

export const deselectBidding = ({ orderId, biddingId, callback }) => ({
  type: `${namespace}/${types.DESELECT_BIDDING}`,
  payload: {
    orderId,
    biddingId,
    callback,
  },
});

export const deselectBiddingSuccess = bidding => ({
  type: types.DESELECT_BIDDING_SUCCESS,
  payload: {
    bidding,
  },
});

export const acceptBidding = orderID => ({
  type: `${namespace}/${types.ACCEPT_BIDDING}`,
  payload: {
    orderID,
  },
});

export const acceptBiddingSuccess = order => ({
  type: types.ACCEPT_BIDDING_SUCCESS,
  payload: {
    order,
  },
});

export const fetchInstantPrice = values => ({
  type: `${namespace}/${types.FETCH_INSTANT_PRICE}`,
  payload: {
    values,
  },
});

export const resetShipmentBiddings = () => ({
  type: `${namespace}/${types.RESET_SHIPMENT_BIDDINGS}`,
});

// reducer

export const saveListOrder = ({ orders = [], pages = {}, params }) => ({
  type: [types.SAVE_LIST_ORDERS],
  payload: {
    orders,
    pages,
    params,
  },
});

export const saveOrderDetail = ({ order }) => ({
  type: [types.SAVE_ORDER_DETAIL],
  payload: {
    order,
  },
});

export const updateOrderInList = ({ order }) => ({
  type: [types.UPDATE_ORDER_IN_LIST],
  payload: {
    order,
  },
});

export const setOrderTemplates = ({ order_templates }) => ({
  type: [types.SET_ORDER_TEMPLATES],
  payload: {
    order_templates,
  },
});

export const setOrderSuggestions = ({ order_suggestions }) => ({
  type: [types.SET_ORDER_SUGGESTIONS],
  payload: {
    order_suggestions,
  },
});

export const saveShipment = ({ shipment }) => ({
  type: [types.SAVE_SHIPMENT],
  payload: {
    shipment,
  },
});

export const saveLocations = ({ locations }) => ({
  type: [types.SAVE_LOCATIONS],
  payload: {
    locations,
  },
});

export const saveShipmentBiddings = ({ shipmentBidding }) => ({
  type: [types.SAVE_SHIPMENT_BIDDINGS],
  payload: {
    shipmentBidding,
  },
});
