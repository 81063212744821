export default {
  'login.email': 'email',
  'login.password': 'password',
  'login.message-invalid-credentials': 'Invalid email or password',
  'login.message-invalid-verification-code': 'Invalid verification code',
  'login.tab-login-credentials': 'Credentials',
  'login.tab-login-mobile': 'Mobile number',
  'login.remember-me': 'Remember me',
  'login.forgot-password': 'Forgot your password?',
  'login.forgot-password-description':
    "Please enter your registered email and we'll send a new password reset for you.",
  'login.enter-email': 'Enter email',
  'login.sign-in-with': 'Sign in with',
  'login.signup': 'Sign up',
  'login.login': 'Login',
  'validation.email.required': 'Please enter your email!',
  'validation.email.wrong-format': 'The email address is in the wrong format!',
  'validation.password.required': 'Please enter your password!',
  'validation.password.twice': 'The passwords entered twice do not match!',
  'validation.password.strength.msg':
    "Please enter at least 6 characters and don't use passwords that are easy to guess.",
  'validation.password.strength.strong': 'Strength: strong',
  'validation.password.strength.medium': 'Strength: medium',
  'validation.password.strength.short': 'Strength: too short',
  'validation.confirm-password.required': 'Please confirm your password!',
  'validation.phone-number.required': 'Please enter your phone number!',
  'validation.phone-number.wrong-format': 'Malformed phone number!',
  'validation.verification-code.required': 'Please enter the verification code!',
  'login.set-password': 'Set login password',
  'login.set-new-password': 'Set new password',
  'login.input-password': 'Password',
  'login.confirm-password': 'Confirm password',
  'login.create-new-password': 'Create new password',
  'login.password_not_match': 'Password not match',
};
