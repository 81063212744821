export const EVENT = 'ShipperWeb';

export const CATEGORY = {
  LOGIN: 'Login',
  LOGIN_SUCCESS: 'LoginSuccess',
  ORDER_CREATION: 'OrderCreation',
  SHIPPER_WEB_FORM: 'ShipperWebForm',
};

export const ACTION = {
  SWP_LOGIN: 'ShipperWebFormLogin',
  LOGIVAN_PRICE: 'LogivanPrice',
  POST_SHIPMENT: 'PostShipment',
};

export const ORDER_TYPE = {
  HYBRIB: 'Hybrid',
  MARKETPLACE: 'Marketplace',
};

export const LOGIN_METHOD = {
  EMAIL: 'loginByEmail',
  PHONE: 'loginByPhone',
};

export const LOGIN_TYPE = {
  NORMAL: 'normalLogin',
  QUICKORDER: 'quickorderLogin',
};
