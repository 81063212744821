import { get } from '@/utils/request';

export const fetchTrucks = () =>
  get({
    endpoint: `/truck_models`,
  });

export const fetchDemo = () =>
  get({
    endpoint: `/truck_models`,
  });

export const fetchBanner = () =>
  get({
    endpoint: `/radio_contents?source=broker_web`,
  });

export const getDistrictIdByName = params =>
  get({
    customUrl: true,
    endpoint: '/broker/district_lookup',
    params,
  });

export const getEContractContent = () =>
  get({
    endpoint: '/html_contents/broker_e_contract',
  });
