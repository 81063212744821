import { fetchTrucks } from '@/services/global';
import { getTaxnumberInfo } from '@/services/company';
import globalSelectors from '@/selectors/globalSelectors';
import Logger from '@/utils/logger';
import * as types from '@/actionTypes/globalTypes';
import actions from '@/actions/globalActions';

export default {
  namespace: types.NAMESPACE_GLOBAL,

  state: {
    collapsed: true,
    notices: [],
    loadedAllNotices: false,
    trucks: [],
    truckModels: [],
    cargoTypes: [],
    cities: [],
    taxNumberInfo: null,
    quickOrderData: {},
  },

  effects: {
    *[types.FETCH_TRUCKS]({}, { call, put }) {
      const response = yield call(fetchTrucks);
      if (response) {
        yield put(actions.saveTrucks({ trucks: response }));
      }
    },
    *[types.CHANGE_TRUCK_MODELS]({ payload }, { put, select }) {
      try {
        const { truck_type_id } = payload;
        if (truck_type_id === -1) {
          // refresh
          const empty = [];
          yield put(actions.saveTruckModels({ truckModels: empty }));
          yield put(actions.saveCargoTypes({ cargoTypes: empty }));
          return;
        }
        const { models: truckModels, cargo_types: cargoTypes } = yield select(state =>
          globalSelectors.getTruckByTruckId(state, truck_type_id)
        );
        yield put(actions.saveTruckModels({ truckModels: truckModels || [] }));
        yield put(actions.saveCargoTypes({ cargoTypes: cargoTypes || [] }));
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.FETCH_TAX_NUMBER_INFO]({ payload }, { put, call }) {
      try {
        const { taxNumber } = payload;
        if (!!taxNumber && taxNumber.length > 9) {
          const taxNumberInfo = yield call(getTaxnumberInfo, taxNumber);
          yield put(actions.saveTaxNumberInfo({ taxNumberInfo }));
        }
      } catch (e) {
        Logger.log(e);
      }
    },
  },
  reducers: {
    [types.CHANGE_LAYOUT_COLLAPSED](state, { payload }) {
      return {
        ...state,
        collapsed: payload.collapsed,
      };
    },
    [types.SAVE_TRUCKS](state, { payload }) {
      return {
        ...state,
        trucks: payload.trucks,
      };
    },
    [types.SAVE_TRUCK_MODELS](state, { payload }) {
      return {
        ...state,
        truckModels: payload.truckModels,
      };
    },
    [types.SAVE_CARGO_TYPES](state, { payload }) {
      return {
        ...state,
        cargoTypes: payload.cargoTypes,
      };
    },
    [types.SAVE_TAX_NUMBER_INFO](state, { payload }) {
      return {
        ...state,
        taxNumberInfo: payload.taxNumberInfo,
      };
    },
    [types.SAVE_QUICKORDER_DATA](state, { payload }) {
      return {
        ...state,
        quickOrderData: payload.formData,
      };
    },
  },

  subscriptions: {
    setup({ history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return history.listen(({ pathname, search }) => {
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
      });
    },
  },
};
