import configs from '@/constants/urlConstants';

export const isDevelopedEnviroment = () => APP_ENV !== 'production';

export const LIST_SERVER = [
  'https://logivan.herokuapp.com',
  'https://logivan-testbed1.herokuapp.com',
  'https://logivan-staging-pr-xxx.herokuapp.com',
];

export const saveServerPathToStorage = serverPath => {
  if (localStorage.getItem('serverPath')) localStorage.removeItem('serverPath');
  localStorage.setItem('serverPath', serverPath);
  window.location.reload();
};

export const getServerPath = () => {
  if (localStorage && localStorage.getItem('serverPath')) return localStorage.getItem('serverPath');
  return configs[APP_ENV].apiEndpoint;
};

export const getDefaultServerPath = () => configs[APP_ENV].apiEndpoint;

export const isUseDevelopedServer = () => getServerPath() !== configs.production.apiEndpoint;

export default {
  api: configs[APP_ENV].apiEndpoint,
  version: configs.version,
  isDevelopedEnviroment,
  LIST_SERVER,
  saveServerPathToStorage,
  getServerPath,
  getDefaultServerPath,
  isUseDevelopedServer,
};
