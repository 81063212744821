const version = 'v2';
//
const development = {
  apiEndpoint: `https://logivan-testbed1.herokuapp.com`,
  localStorageKey: 'logivan@develop:user',
};

const production = {
  apiEndpoint: `https://logivan.herokuapp.com`,
  localStorageKey: 'logivan:user',
};

const staging = {
  apiEndpoint: `https://logivan-staging-pr-491.herokuapp.com`,
  localStorageKey: 'logivan@develop:user',
};

const testbed = {
  apiEndpoint: `https://logivan-testbed1.herokuapp.com`,
  localStorageKey: 'logivan@testbed:user',
};

const deployNow = {
  apiEndpoint: `https://logivan-staging-pr-878.herokuapp.com`,
  localStorageKey: 'logivan@testbed:user',
};

const configs = {
  development,
  production,
  staging,
  testbed,
  deployNow,
  version,
};
export default configs;
