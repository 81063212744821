import { post } from '@/utils/request';

export const fakeAccountLogin = params =>
  post({
    endpoint: `/brokers/login`,
    params: {
      credentials: params,
    },
  });

export const getAuthenticationToken = accountkitData =>
  post({
    endpoint: `/brokers/register`,
    params: {
      broker: accountkitData,
    },
    shouldStringify: false,
  });

export const fakeRegister = params =>
  post({
    endpoint: '/api/register',
    params,
  });
