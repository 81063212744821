import TagManager from 'react-gtm-module';
import { getUser } from '@/utils/authority';
import { APPLOGIVAN_HREF } from '@/constants/globalConstants';
import { EVENT, CATEGORY, ACTION, ORDER_TYPE } from '@/constants/gtmConstants';

const tagManagerArgs = {
  gtmId: GTM_ID,
  auth: GTM_AUTH,
  preview: GTM_PREVIEW,
};

export const initGTM = () => {
  TagManager.initialize(tagManagerArgs);
};

const pushTagManager = ({ userId: user_id, ...params }) => {
  const tagData = {
    ...params,
    user_id,
    event: EVENT,
  };
  window.dataLayer.push(tagData);
};

const trackQuickOrder = ({ step, phone, page, userId }) => {
  pushTagManager({
    eventCategory: CATEGORY.SHIPPER_WEB_FORM,
    eventAction: step,
    eventLabel: phone,
    page: page || '',
    step: step || '',
    userId: userId || '',
    userPhone: phone || '',
  });
};

const trackLogivanPrice = hasSalesPrice => {
  const user = getUser();
  const { id: userId, email: userEmail, phone_number: userPhone } = user;
  pushTagManager({
    eventCategory: CATEGORY.ORDER_CREATION,
    eventAction: ACTION.LOGIVAN_PRICE,
    eventLabel: hasSalesPrice ? ORDER_TYPE.HYBRIB : ORDER_TYPE.MARKETPLACE,
    userId,
    userEmail,
    userPhone,
  });
};

const trackPostShipment = hasSalesPrice => {
  const user = getUser();
  const { id: userId, email: userEmail, phone_number: userPhone } = user;
  pushTagManager({
    eventCategory: CATEGORY.ORDER_CREATION,
    eventAction: ACTION.POST_SHIPMENT,
    eventLabel: hasSalesPrice ? ORDER_TYPE.HYBRIB : ORDER_TYPE.MARKETPLACE,
    userId,
    userEmail,
    userPhone,
  });
};

const trackLogin = ({ method, value, params = {} }) => {
  pushTagManager({
    eventCategory: CATEGORY.LOGIN,
    eventAction: method,
    eventLabel: value,
    page: window.location.href,
    step: params.step || '',
    userId: params.userId || '',
    userPhone: params.phone || '',
  });
};

const trackLoginSuccess = ({ method, value, params = {} }) => {
  pushTagManager({
    eventCategory: CATEGORY.LOGIN_SUCCESS,
    eventAction: method,
    eventLabel: value,
    page: APPLOGIVAN_HREF,
    step: params.step || '',
    userId: params.userId || '',
    userPhone: params.phone || '',
  });
};

export default {
  trackLogin,
  trackLoginSuccess,
  trackQuickOrder,
  trackPostShipment,
  trackLogivanPrice,
};
