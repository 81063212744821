export default {
  'global.action': 'Action',
  'global.overview': 'Overview',
  'global.truck_type': 'Truck type',
  'global.truck_model': 'Truck model',
  'global.all': 'All',
  'global.filter': 'Filter',
  'global.clear': 'Clear',
  'global.welcome_dashboard': 'Welcome dashboard',
  'global.dashboard_description': 'LOGIVAN helps you create, schedule, and manage your shipments',
  'global.create_new_order_now': 'Create new order now',
  'global.are-you-sure': 'Are you sure ?',
  'global.yes': 'Yes',
  'global.no': 'No',
  'global.optional': 'Optional',
  'global.view_images': 'View images',
  'global.remove_images': 'Remove images',
  'global.click_here_to_upload_images': 'Click here to upload images',
  'global.info': 'Information',
  'global.no-name': 'No name',
  'global.driver': 'Driver',
  'global.plate-number': 'Plate number',
  'global.no-info': 'No Information',
  'global.transport-capacity': 'Transport capacity',
  'global.length': 'length',
  'global.width': 'width',
  'global.height': 'height',
  'global.tons': 'tons',
  'global.download': 'Download',
  'global.cancel': 'Cancle',
  'global.prev-month': 'Prev month',
  'global.next-month': 'Next month',
  'global.from-date': 'From date',
  'global.to-date': 'To date',
  'global.hello': 'Hello',
  'global.export_data': 'Export',
  'global.account.owner': 'Account owner',
  'global.account.logivan_name': 'TRANSPORT TECHNOLOGY CO., LTD',
  'global.account.number': 'Account number',
  'global.account.bank': 'Bank',
  'global.account.bank_name':
    'TMCP Sai Gon Thuong Tin - CN Thu Do - Phong giao dich Hoan kiem (SACOMBANK)',
  'global.account.message_title': 'Message',
  'global.account.message_content': 'Name & Phone number - ID order (in order list)',
  'global.something_went_wrong': 'Something went wrong',
  'global.come_back_later': 'Come back later',
  'global.go_to_home': 'Go to Homepage',
  'global.read-and-agree-with-terms': 'I have read and agree to the terms from Logivan',
  'global.service-agreement': 'Contract for the agreement of goods transport service',
  'global.i-agree': 'I agree',
  'global.description-reset-password':
    'We have sent an email to reset your new password via {email}. Please check and see in your inbox.',
  'global.complete': 'Comeback Login page',
};
