import { get, patch, post } from '@/utils/request';

export async function getListOrder(params) {
  return get({
    customUrl: true,
    endpoint: `/broker/orders`,
    params,
  });
}

export const getOrderById = orderId =>
  get({
    customUrl: true,
    endpoint: `/broker/orders/${orderId}`,
  });

export const updateOrderStatus = (orderId, type) =>
  patch({
    customUrl: true,
    endpoint: `/broker/orders/${orderId}/action/${type}`,
  });

export const fetchOrderTemplates = brokerCompanyId =>
  get({
    customUrl: true,
    endpoint: `/broker/broker_companies/${brokerCompanyId}/order_templates`,
  });

export const createOrder = shipment =>
  post({
    customUrl: true,
    endpoint: '/broker/orders',
    params: {
      order: shipment,
    },
  });

export const getPackagingSpecs = brokerCompanyId =>
  get({
    customUrl: true,
    endpoint: `/broker/api/v1/broker_companies/${brokerCompanyId}/packaging_specs`,
  });

export const createPackagingSpec = (brokerCompanyId, packagingSpec) =>
  post({
    customUrl: true,
    endpoint: `/broker/api/v1/broker_companies/${brokerCompanyId}/packaging_specs`,
    params: {
      packaging_spec: packagingSpec,
    },
  });

export const exportFilteredOrder = params =>
  post({
    customUrl: true,
    endpoint: `/broker/orders/export`,
    params,
  });

export const exportProcessing = key =>
  get({
    customUrl: true,
    endpoint: `/broker/orders/export_progress?key=${key}`,
  });

// Shipment Process API
export const getShipmentDetail = orderID =>
  get({
    customUrl: true,
    endpoint: `/broker/api/v1/shipments/${orderID}`,
  });

export const getShipmentDocument = shipmentId =>
  get({
    customUrl: true,
    endpoint: `/broker/shipments/${shipmentId}/documents`,
  });

export const getMapOfDriver = (driverId, startDate, endDate) =>
  get({
    endpoint: `/drivers/${driverId}/locations?shipment=true&from_date=${startDate}&to_date=${endDate}`,
  });

// bidding
export const fetchBiddings = (orderId, { page, limit, amount, isFilterSelected }) =>
  get({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/biddings`,
    params: {
      page,
      limit,
      filter_amount: amount,
      filter_selected: isFilterSelected,
    },
  });

export const selectBidding = (orderId, biddingId) =>
  patch({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/biddings/${biddingId}/select`,
  });

export const deselectBidding = (orderId, biddingId) =>
  patch({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/biddings/${biddingId}/deselect`,
  });

export const acceptBidding = orderId =>
  post({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/biddings/accept`,
  });

export const getDriverById = driverId =>
  get({
    endpoint: `/drivers/${driverId}`,
  });

export const fetchInstantPrice = values =>
  post({
    customUrl: true,
    endpoint: '/broker/orders/list_price_details',
    params: {
      order: values,
    },
  });

export const getTruckModelIds = params =>
  get({
    customUrl: true,
    endpoint: '/broker/truck_models/suggest',
    params,
  });
