import { get, patch, post } from '@/utils/request';
import { getUser } from '@/utils/authority';

export const queryCurrent = () =>
  get({
    endpoint: `/brokers/${getUser().id}`,
  });

export const updateUser = (brokerId, values) =>
  patch({
    endpoint: `/brokers/${brokerId}`,
    params: {
      broker: values,
    },
  });

export const uploadIdentity = (brokerId, values) =>
  post({
    endpoint: `/brokers/${brokerId}/documents`,
    params: {
      document: values,
    },
  });
