import { NAMESPACE_ORDER } from '@/actionTypes/orderTypes';

const getDataOrders = state => state[NAMESPACE_ORDER].orders;
const getParamsFilter = state => state[NAMESPACE_ORDER].params;
const getOrders = state => state[NAMESPACE_ORDER].orders.list;
const getOrderSuggestions = state => state[NAMESPACE_ORDER].order_suggestions;
const getOrderDetail = state => state[NAMESPACE_ORDER].orderDetail;
const getShipment = state => state[NAMESPACE_ORDER].shipment;
const getLocations = state => state[NAMESPACE_ORDER].locations;
const getListBiddings = state => state[NAMESPACE_ORDER].listBiddings;
const getListSelectedBiddings = state => state[NAMESPACE_ORDER].listSelectedBiddings;
const getBidAmount = state => state[NAMESPACE_ORDER].selectBidAmount;
const getBidPrice = state => state[NAMESPACE_ORDER].selectBidPrice;
const getPageListBiddings = state => state[NAMESPACE_ORDER].pageListBiddings;
const getShipmentBiddings = state => state[NAMESPACE_ORDER].shipmentBidding;

const orderSelectors = {
  getDataOrders,
  getOrderDetail,
  getParamsFilter,
  getOrders,
  getOrderSuggestions,
  getShipment,
  getLocations,
  getShipmentBiddings,
  getListBiddings,
  getListSelectedBiddings,
  getBidAmount,
  getBidPrice,
  getPageListBiddings,
};

export default orderSelectors;
