/* eslint-disable */
import { routerRedux } from 'dva/router';
import { formatMessage } from 'umi/locale';
import { fakeAccountLogin, getAuthenticationToken } from '@/services/login';
import { setAuthority, logout, saveCredentials } from '@/utils/authority';
import { reloadAuthorized } from '@/utils/Authorized';
import { updateUser } from '@/services/profile';
import { message } from 'antd';
import Logger from '@/utils/logger';
import get from 'lodash/get';
import * as types from '@/actionTypes/loginTypes';
import * as profileActions from '@/actions/profileActions';
import actions from '@/actions/loginActions';
import globalSelectors from '@/selectors/globalSelectors';
import { convertPhoneNumber } from '@/utils/phone-number';
import GoogleTagManagerServices from '@/services/google-tag-manager';
import { LOGIN_METHOD, LOGIN_TYPE, ACTION } from '@/constants/gtmConstants';

export default {
  namespace: types.NAMESPACE_LOGIN,

  state: {
    status: undefined,
  },

  effects: {
    *[types.REDIRECT_AFTER_LOGIN]({ payload }, { put, select }) {
      const { broker, loginType, method } = payload;

      const name = get(broker, 'name', '');
      const brokerId = get(broker, 'id', '');
      const phone = get(broker, 'phone_number', '');
      const email = get(broker, 'email', '');

      if (loginType === LOGIN_TYPE.QUICKORDER) {
        if (!name) {
          const formData = yield select(state => globalSelectors.getQuickOrderData(state));
          yield put(
            actions.handleUpdateUserRegister({ brokerId, values: { name: formData.name } })
          );
        }
        GoogleTagManagerServices.trackQuickOrder({
          phone,
          userId: brokerId,
          step: ACTION.SWP_LOGIN,
        });
        yield put(routerRedux.push('/order-new?quick-order=true'));
        return;
      }
      GoogleTagManagerServices.trackLoginSuccess({
        method,
        value: method === LOGIN_METHOD.PHONE ? phone : email,
        params: {
          phone,
          userId: brokerId,
        },
      });
      yield put(routerRedux.push('/'));
    },
    *[types.FETCH_AUTHENTICATION_TOKEN]({ payload }, { call, put, select }) {
      try {
        const response = yield call(getAuthenticationToken, payload);
        if (response) {
          const { broker } = response;
          const { loginType } = payload;
          const newBroker = { ...broker };
          if (loginType === LOGIN_TYPE.QUICKORDER) {
            const { name } = yield select(state => globalSelectors.getQuickOrderData(state));
            newBroker.name = name;
          }
          yield put(actions.saveLogin({ broker: newBroker }));
          reloadAuthorized();
          yield put(actions.redirectAfterLogin({ broker, loginType, method: LOGIN_METHOD.PHONE }));
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.LOGIN]({ payload }, { call, put }) {
      try {
        const { email } = payload;
        GoogleTagManagerServices.trackLogin({
          method: LOGIN_METHOD.EMAIL,
          value: email,
        });
        const response = yield call(fakeAccountLogin, payload);
        // Login successfully
        if (response) {
          const { broker } = response;
          yield put(actions.saveLogin({ broker }));
          reloadAuthorized();
          yield put(actions.redirectAfterLogin({ broker, method: LOGIN_METHOD.EMAIL }));
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.HANDLE_ACCOUNT_KIT_CALLBACK]({ payload }, { call, put }) {
      if (!payload) {
        Logger.warn('user canceled');
      } else {
        return yield put(actions.fetchAuthenticationToken(payload));
      }
      return null;
    },
    *[types.HANDLE_VERIFY_SMS]({ payload }, { put }) {
      const countryCode = '+84';
      const { phone_number, loginType } = payload;
      const phoneNumber = convertPhoneNumber(phone_number);
      if (loginType !== LOGIN_TYPE.QUICKORDER) {
        GoogleTagManagerServices.trackLogin({
          method: LOGIN_METHOD.PHONE,
          value: phoneNumber,
        });
      }
      try {
        const promise = new Promise((resolve, reject) => {
          AccountKit.login(
            'PHONE',
            { countryCode, phoneNumber: phoneNumber }, // will use default values if not specified
            response => {
              // callback
              if (response.status === 'PARTIALLY_AUTHENTICATED') {
                const values = {
                  phone_number: `${countryCode}${
                    phoneNumber[0] === '0' ? phoneNumber.slice(1) : phoneNumber
                  }`,
                  AK_id: '',
                  AK_token: response.code,
                };
                resolve(values);
              } else if (response.status === 'NOT_AUTHENTICATED') {
                reject();
              } else if (response.status === 'BAD_PARAMS') {
                reject();
              }
            }
          );
        });
        const response = yield promise;
        yield put(actions.handleAccountKitCallback({ loginType, ...response }));
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.HANDLE_UPDATE_USER_REGISTER]({ payload }, { call, put }) {
      const { brokerId, values } = payload;
      try {
        const response = yield call(updateUser, brokerId, values);
        if (response) {
          const { broker } = response;
          // update store
          yield put(profileActions.saveCurrentUser({ user: broker }));
          // update localstorage
          yield put(actions.saveLogin({ broker }));
          message.success(formatMessage({ id: 'form.edit-success' }));
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.LOGOUT](_, { put }) {
      const roles = [];
      const user = {};
      logout();
      setAuthority(roles);
      saveCredentials(user);
      reloadAuthorized();
      yield put(profileActions.resetAccountAfterLogout());
      yield put(routerRedux.push('/user/login'));
    },
    *[types.SAVE_LOGIN]({ payload }, { put }) {
      try {
        const {
          broker,
          broker: { roles },
        } = payload;
        if (roles && roles.length === 0) broker.roles = ['user'];
        setAuthority(broker.roles);
        saveCredentials(broker);
        reloadAuthorized();
        yield put(profileActions.saveCurrentUser({ user: broker }));
      } catch (err) {
        Logger.log(err);
      }
    },
  },

  reducers: {},
};
