import React from 'react';
import { Router as DefaultRouter, Route, Switch } from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/_renderRoutes';
import RendererWrapper0 from '/home/quan/logivan/shippers/src/pages/.umi-production/LocaleWrapper.jsx'
import _dvaDynamic from 'dva/dynamic'

let Router = require('dva/router').routerRedux.ConnectedRouter;

let routes = [
  {
    "path": "/dat-hang-nhanh",
    "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "layouts__QuickOrderLayout" */'../../layouts/QuickOrderLayout'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
    "exact": true
  },
  {
    "path": "/user",
    "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "layouts__UserLayout" */'../../layouts/UserLayout'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
    "routes": [
      {
        "path": "/user",
        "redirect": "/user/login",
        "exact": true
      },
      {
        "path": "/user/login",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__User__Login" */'../User/Login'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/user/recovery",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__User__RecoveryPassword" */'../User/RecoveryPassword'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/user/reset-password",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__User__NewPassword" */'../User/NewPassword'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/user/forgot-password",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__User__NewPassword" */'../User/NewPassword'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "layouts__BasicLayout" */'../../layouts/BasicLayout'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
    "Routes": [require('../Authorized').default],
    "authority": [
      "superadmin",
      "admin",
      "user",
      "owner",
      "coordinator"
    ],
    "routes": [
      {
        "path": "/",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__Home__Home" */'../Home/Home'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/order-new",
        "name": "create_new_order",
        "icon": "file-add",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__OrderCreation" */'../OrderCreation'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/order",
        "name": "order",
        "icon": "switcher",
        "routes": [
          {
            "path": "/order",
            "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "layouts__BasicLayout" */'../OrderList'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
            "exact": true
          },
          {
            "path": "/order/:id",
            "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "layouts__BasicLayout" */'../OrderDetail'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
            "exact": true
          }
        ]
      },
      {
        "path": "/billing",
        "name": "billing",
        "icon": "switcher",
        "routes": [
          {
            "path": "/billing",
            "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "layouts__BasicLayout" */'../Billing'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
            "exact": true
          }
        ]
      },
      {
        "name": "profile",
        "icon": "user",
        "path": "/profile",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__Profile" */'../Profile'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/shipment/:id",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__Shipment" */'../Shipment'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
        "routes": [
          {
            "path": "/shipment",
            "redirect": "/shipment/:id/info",
            "exact": true
          },
          {
            "path": "/shipment/:id/info",
            "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__Shipment" */'../Shipment/ShipmentInfo'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
            "exact": true
          },
          {
            "path": "/shipment/:id/documents",
            "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__Shipment" */'../Shipment/ShipmentDocuments'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
            "exact": true
          }
        ]
      },
      {
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__404" */'../404'),
  LoadingComponent: require('/home/quan/logivan/shippers/src/components/common/PageLoading/index').default,
}),
        "exact": true
      }
    ]
  }
];
window.g_plugins.applyForEach('patchRoutes', { initialValue: routes });

export default function RouterWrapper() {
  return (
<RendererWrapper0>
          <Router history={window.g_history}>
      { renderRoutes(routes, {}) }
    </Router>
        </RendererWrapper0>
  );
}
