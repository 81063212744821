export default {
  'order.order': 'Order',
  'order.trip': 'Trip',
  'order.pickup-location': 'Pickup location',
  'order.dropoff-location': 'Dropoff location',
  'order.information': 'Order Information',
  'order.order-detail': 'Order detail',
  'order.order-weight': 'Order weight(tons)',
  'order.created-date': 'Created date',
  'order.pickup_datetime': 'Pickup datetime',
  'order.pickup_date': 'Pickup date',
  'order.pickup_time': 'Pickup time',
  'order.dropoff-datetime': 'Dropoff datetime',
  'order.creator': 'Creator',
  'order.order-list': 'Order list',
  'order.order-type': 'Order type',
  'order.truck_type_id': 'Truck type',
  'order.truck_model_id': 'Truck model',
  'order.bidding_status': 'Status',
  'order.cargo_types': 'Cargo types',
  'order.title.cargo_weight': 'Cargo weight',
  'order.title.cargo_size': 'Cargo size',
  'order.cargo_weight': '{weight} tons',
  'order.text.truck_quantity': 'Truck quantity',
  'order.truck_quantity': '{quantity} cars',
  'order.order-location': 'Order location',
  'order.order-information': 'Order information',
  'order.truck-information': 'Truck information',
  'order.service_type': 'Service type',
  'order.price': 'Price',
  'order.images': 'Images',
  'order.newly_created': 'Newly created',
  'order.already_have_truck': 'Already have truck',
  'order.canceled': 'Cancel',
  'order.pickup_address': 'Pick up address',
  'order.dropoff_address': 'Drop off address',
  'order.example_adress': 'Example: Cat Lat',
  'order.order_id': 'Order ID',
  'order.search': 'Search',
  'order.created_order_broker': 'Created order broker',
  'order.example_broker': 'Example: Nguyen Van A',
  'order.address_is_incorrect': 'Address is incorrect',
  'order.select_your_registered_trips_from_the_list': 'Select your registered trips from the list',
  'order.add_a_new_trip': 'Add a new trip',
  'order.your_trip_name': 'Your trip {{title}}',
  'order.order_with_new_trip': 'Order with new trip',
  'order.shipment-detail': 'Shipment detail',
  'order.preferences': 'Preferences',
  'order.is_insurance_requested': 'Request insurance',
  'order.hide_contact': 'Hide contact from drivers',
  'order.is_tax_invoice_requested': 'Request VAT tax invoice',
  'order.is_whole_truck': 'Request whole truck',
  'order.is_share_truck': 'Request share truck',
  'order.additional info': 'Additional info',
  'order.shipment_photo': 'Shipment photo',
  'order.business_photo': 'Business photo',
  'order.trip-detail': 'Trip detail',
  'order.pickup-location-do': 'Delivery order of pickup location',
  'order.dropoff-location-do': 'Delivery order of dropoff location',
  'order.pickup': 'pickup',
  'order.dropoff': 'dropoff',
  'order.delivery-order-code': 'Delivery Order code',
  'order.code-product-description': 'Code / Product description',
  'order.total-unit': 'Total unit',
  'order.total-weight': 'Total weight',
  'order.unit': 'Unit',
  'order.weight': 'Weight',
  'order.accepted': 'Accepted',
  'order.pending': 'Pending',
  'order.show-bidding': 'Show bidding',
  'order.list-biddings': 'List biddings',
  'order.order-bidding': 'Order bidding',
  'order.accept-bidding': 'Accept bidding',
  'order.reject-bidding': 'Reject bidding',
  'order.bidding-created-at': 'Bidding created at',
  'order.billing': 'Billing',
  'order.total-billing': 'Total billing',
  'order.total-billing-of-year': 'Total billing of year',
  'order.total-billing-of-month': 'Total billing of month',
  'order.cost-type': 'Cost type',
  'order.fee-name': 'Fee name',
  'order.quantity': 'Quantity',
  'order.total-price': 'Total Price',
  'order.tax-rate': 'VAT',
  'order.total-price-with-tax': 'Total Price (VAT)',
  'order.assigned-person': 'Assigned Person',
  'order.posted': 'Looking for truck',
  'order.expired': 'Expired',
  'order.cancelled': 'Cancelled',
  'order.completed': 'Completed',
  'order.has_bidding': 'Has bidding',
  'order.bidding_quoted': 'Has bidding',
  'order.bidding_accepted': 'Accepted',
  'order.shipment_new': 'Found truck',
  'order.shipment_in_transit': 'In transit',
  'order.stt': 'ID',
  'order.route': 'Route',
  'order.shipment-documents': 'View detail shipment images',
  'shipment.trip': 'Trip',
  'shipment.share-trip': 'Share trip',
  'shipment.documents': 'Documents',
  'shipment.created': 'Driver accepted',
  'shipment.picked_up': 'Pickup completed',
  'shipment.arrived_pickup': 'Driver arrived pickup location',
  'shipment.in-transit': 'In transit',
  'shipment.arrived_dropoff': 'Driver arrived dropoff location',
  'shipment.dropped_off': 'Delivered',
  'shipment.documents-picked_up': 'Documents picked up',
  'shipment.documents-dropped_off': 'Documents dropped off',
  'shipment.follow-trip': 'Follow trip',
  'order.enter-information': 'Enter information',
  'order.logivan-bidding': 'Bidding from Logivan',
  'order.order-booking': 'Order booking',
  'order.whole_truck': 'Whole truck',
  'order.shared_truck': 'Shared truck',
  'order.does_not_include_vat': 'Does not include VAT',
  'order.enter_cargo_dimensions_to_get_shared_truck_price':
    'Enter cargo dimensions to get shared truck price',
  'order.LGV_have_not_supported_for_this_routes_and_cargo':
    'LOGIVAN have not supported for this routes and cargo.',
  'order.please_press': 'Please press',
  'order.to_view_order_and_LGV_will': 'to view order and LOGIVAN will',
  'order.direct_bidding': 'send direct bidding',
  'order.for_you': 'for you',
  'order.create_order_success': 'Congratulations, You Got a New Car!',
  'order.text_logivan_contact_for_you': 'LOGIVAN will contact you immediately to lock the car!',
  'order.text_back_home': 'Back to order list in',
  'order.sum': 'Sum',
  'order.pretax_price': 'Pre Tax price',
  'order.sub_total_price': 'Cước vận chuyển',
  'order.preview.pickup_location': 'Pickup location',
  'order.preview.dropoff_location': 'Dropoff location',
  'order.preview.pickup_date': 'Pickup date',
  'order.preview.truck_type': 'Truck type',
  'order.preview.cargo_type': 'Cargo type',
  'order.preview.notes': 'Notes',
  'order.message.choice_shared_or_whole': 'Please choice Whole truck or Shared truck',
  'order.text.no_suitable_price': 'There is no suitable price for this vehicle',
  'order.text.no_shared_price_for_many_truck': 'LGV has not been able to support grafting on 1 car',
  'order.text.with_this_payload_you_can_only_ride_the_vehicle':
    'With this payload you can only ride the vehicle',
  'order.title.bidding': 'Bidding for order',
  'order.btn.ready_for_transport': 'Ready transport',
  'order.btn.select_bidding': 'Select bidding',
  'order.btn.unselect_bidding': 'Unselect',
  'order.btn.detail': 'Detail',
  'order.btn.close': 'Close',
  'order.btn.cancel': 'Cancel',
  'order.btn.confirm': 'Confirm',
  'order.title.bidding_detail': 'Bidding detail',
  'order.title.shipment_info': 'Shipment information',
  'order.title.transport_capacity': 'Transport capacity',
  'order.title.sorry': 'Sorry!',
  'order.title.confirm_quote': 'Confirm quote!',
  'order.title.filter': 'Filter',
  'order.desc.suggested_price': 'Suggested price',
  'order.desc.quote_price': 'Quote price for',
  'order.desc.date_bidding': 'Date bidding',
  'order.desc.bidded_truck_for_order': 'Vehicles have quoted shipping prices for orders',
  'order.desc.error_not_enough_cars':
    'The number of transport vehicles is still not enough to carry out order acceptance. You need to find enough cars to continue.',
  'order.desc.notification_enough_cars':
    'The number of vehicles transported from quotation is sufficient as required. Are you sure to accept the quote for this order.',
  'order.desc.not_bidding_for_order': 'The order has no quotation',
  'order.desc.truck-quantity': 'Truck quantity',
  'order.desc.temporary-price': 'Temporary price',
  'order.desc.warning-total-bid':
    'You cannot select this quote because the number of vehicles will exceed the total number of vehicles required.',
  'order.car': 'car',
  'order.bidding_status.selected': 'Selected',
  'order.bidding_status.pending': 'Pending',
  'order.bidding_status.rejected': 'Rejected',
  'order.bidding_status.cancelled': 'Cancelled',
  'order.today': 'Today',
  'order.dayago': '{day} days ago',
};
