import * as types from '@/actionTypes/loginTypes';

const namespace = types.NAMESPACE_LOGIN;

export const login = payload => ({
  type: `${namespace}/${types.LOGIN}`,
  payload,
});

export const fetchAuthenticationToken = payload => ({
  type: types.FETCH_AUTHENTICATION_TOKEN,
  payload,
});

export const handleAccountKitCallback = payload => ({
  type: types.HANDLE_ACCOUNT_KIT_CALLBACK,
  payload,
});

export const handleVerifySMS = payload => ({
  type: `${namespace}/${types.HANDLE_VERIFY_SMS}`,
  payload,
});

export const handleUpdateUserRegister = payload => ({
  type: `${namespace}/${types.HANDLE_UPDATE_USER_REGISTER}`,
  payload,
});

export const logout = payload => ({
  type: `${namespace}/${types.LOGOUT}`,
  payload,
});

export const saveLogin = ({ broker }) => ({
  type: types.SAVE_LOGIN,
  payload: {
    broker,
  },
});

export const redirectAfterLogin = ({ broker, loginType, method }) => ({
  type: types.REDIRECT_AFTER_LOGIN,
  payload: {
    method,
    broker,
    loginType,
  },
});

export default {
  login,
  fetchAuthenticationToken,
  handleAccountKitCallback,
  handleVerifySMS,
  handleUpdateUserRegister,
  logout,
  saveLogin,
  redirectAfterLogin,
};
