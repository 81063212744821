// use localStorage to store the authority info, which might be sent from server in actual project.
export function getAuthority(str) {
  // return localStorage.getItem('antd-pro-authority') || ['admin', 'user'];
  const authorityString =
    typeof str === 'undefined' ? localStorage.getItem('antd-pro-authority') : str;
  // authorityString could be admin, "admin", ["admin"]
  let authority;
  try {
    authority = JSON.parse(authorityString);
  } catch (e) {
    authority = authorityString;
  }
  if (typeof authority === 'string') {
    return [authority];
  }
  return authority;
}

export const logout = (cb = false) => {
  delete localStorage.access_token;
  delete localStorage.development;
  delete sessionStorage.development;
  delete localStorage['antd-pro-authority'];
  if (cb) cb();
};

export function setAuthority(authority) {
  const proAuthority = typeof authority === 'string' ? [authority] : authority;
  return localStorage.setItem('antd-pro-authority', JSON.stringify(proAuthority));
}

export const saveCredentials = user => {
  const { authentication_token: accessToken, ...info } = user;

  if (accessToken) {
    localStorage.access_token = accessToken;
    sessionStorage.access_token = accessToken;
  }

  localStorage.development = JSON.stringify(info);
  sessionStorage.development = JSON.stringify(info);
};

export const getUser = () => {
  let user = null;

  if (localStorage.development) {
    user = JSON.parse(localStorage.development);
  } else if (sessionStorage.development) {
    user = JSON.parse(sessionStorage.development);
  }

  return user;
};

export const getAccessToken = () =>
  sessionStorage.access_token ? sessionStorage.access_token : localStorage.access_token;
